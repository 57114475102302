import axios from 'axios';

const axiosWithToken = axios.create();

axiosWithToken.interceptors.request.use(
    (config) => {
        const idToken = localStorage.getItem('idToken')!;
        if (idToken) {
            config.headers['Authorization'] = `Bearer ${idToken}`;
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosWithToken;
