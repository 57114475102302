import '../../styling/pricing.css';
import React, { useEffect, useState } from 'react';
import Tooltip from '../constants/Tooltip';
import { auth } from '../..';
import IFramePopUp from '../popUps/iframePopUp';
import { bizcrunchAppLiveUrl, bizCrunchchArticleUrl } from 'components/utils/network/endpoints';
import axiosWithToken from 'axiosWithToken';

interface PricingIFrameProps {
    userP: any;
}

interface Plan {
    id: string;
    Frequency: string;
    Rate: number;
}

const PricingIFrame: React.FC<PricingIFrameProps> = ({ userP }) => {
    const baseURL2 = process.env.REACT_APP_BASEURL2;

    const [toggle, setToggle] = useState<string>('monthly');
    const [sliderChoice, setSliderChoice] = useState<number>(1);
    const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

    const [monthPlans, setMonthPlans] = useState<Plan[]>([]);
    const [yearPlans, setYearPlans] = useState<Plan[]>([]);

    const [showPopUp, setShowPopUp] = useState<boolean>(false);

    const primary600 = getComputedStyle(document.body).getPropertyValue('--primary-600');

    useEffect(() => {
        if (auth.currentUser) {
            getPlanData();
        }
    }, [auth.currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (monthPlans.length > 0 && yearPlans.length > 0) {
            if (toggle === 'monthly') {
                setSelectedPlan(monthPlans[sliderChoice - 1]);
            } else {
                setSelectedPlan(yearPlans[sliderChoice - 1]);
            }
        }
    }, [sliderChoice, toggle, monthPlans.length, yearPlans.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const getPlanData = async () => {
        try {
            const response = await axiosWithToken.get(`${baseURL2}api/stripe/plans/v2`);
            const plans: Plan[] = response.data;

            const monthArray: Plan[] = [];
            const yearArray: Plan[] = [];

            plans?.forEach((plan) => {
                if (plan.id !== 'free') {
                    if (plan.Frequency === 'monthly') {
                        monthArray.push(plan);
                    } else {
                        yearArray.push(plan);
                    }
                }
            });

            const mPlans = monthArray.sort((a, b) => a.Rate - b.Rate);
            const yPlans = yearArray.sort((a, b) => a.Rate - b.Rate);

            mPlans.shift();
            yPlans.shift();

            setMonthPlans(mPlans);
            setYearPlans(yPlans);
        } catch (error) {
            console.error('Error fetching plan data:', error);
        }
    };

    const calculatePrice = (): number => {
        if (toggle === 'monthly') {
            switch (sliderChoice) {
                case 1:
                    return 90;
                case 2:
                    return 160;
                case 3:
                    return 280;
                default:
                    return 90;
            }
        } else {
            switch (sliderChoice) {
                case 1:
                    return 972;
                case 2:
                    return 1728;
                case 3:
                    return 3024;
                default:
                    return 972;
            }
        }
    };

    const calculateCredits = (): number => {
        const multiplier = toggle === 'monthly' ? 1 : 12;

        switch (sliderChoice) {
            case 1:
                return 1000 * multiplier;
            case 2:
                return 2000 * multiplier;
            case 3:
                return 4000 * multiplier;
            default:
                return 1000 * multiplier;
        }
    };

    const purchasePlan = () => {
        setShowPopUp(true);
    };

    const openArticle = () => {
        const url = bizCrunchchArticleUrl;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const register = () => {
        const url = bizcrunchAppLiveUrl;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const successfulPayment = () => {
        // handle successful payment
    };

    return (
        <div className='column gap57 ai-centre marginTop20'>
            <span className='gray-700 semibold display-xl'>Pricing</span>

            <div className='pricingToggle'>
                <div className={`pricingToggleOption ${toggle === 'monthly' ? 'selected' : ''}`} onClick={() => setToggle('monthly')}>
                    <span className='text-sm'>Monthly</span>
                </div>
                <div className={`pricingToggleOption ${toggle === 'yearly' ? 'selected' : ''}`} onClick={() => setToggle('yearly')}>
                    <span className='text-sm'>Annually</span>
                    <div className='pricingToggleDiscount row jc-centre ai-centre text-xs bold'>Save 10%</div>
                </div>
            </div>

            <div className='column ai-centre gap32 z200 pricingTile padding24'>
                <div className='row'>
                    <div className='column ai-start width50 gap40 padding24'>
                        <div className='column ai-start gap8'>
                            <span className='gray-700 bold display-xs'>Export credits</span>
                            <span className='text-md regular'>Choose the number of export credits</span>
                        </div>

                        <div className='column gap8'>
                            <input
                                type='range'
                                min='1'
                                max='3'
                                value={sliderChoice}
                                className='pricingSlider'
                                id='myRange'
                                onChange={(e) => setSliderChoice(Number(e.target.value))}
                                style={{
                                    background: `linear-gradient(to right, ${primary600} 0%, ${primary600} ${50 * (sliderChoice - 1)
                                        }%, #F2F4F7 ${50 * (sliderChoice - 1)}%, #F2F4F7 100%)`,
                                }}
                            />

                            <div className='row width100 jc-between'>
                                <div className='column gap4 ai-end'>
                                    <span className='regular text-md'>1000</span>
                                </div>
                                <div className='column gap4 ai-end'>
                                    <span className='regular text-md'>2000</span>
                                    <span className='primary-700 bold text-xs'>Save 20%</span>
                                </div>
                                <div className='column gap4 ai-end'>
                                    <span className='regular text-md'>4000</span>
                                    <span className='primary-700 bold text-xs'>Save 30%</span>
                                </div>
                            </div>
                        </div>

                        <div className='column ai-start gap16'>
                            <div className='row ai-centre gap4'>
                                <span className='gray-700 bold display-xs'>£{calculatePrice()}</span>
                                <span className='gray-400 medium text-lg'>+VAT</span>
                            </div>
                            <div className='row ai-centre gap8'>
                                <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M7.9987 11.3333C7.9987 13.1743 9.49108 14.6667 11.332 14.6667C13.173 14.6667 14.6654 13.1743 14.6654 11.3333C14.6654 9.49238 13.173 8 11.332 8C9.49108 8 7.9987 9.49238 7.9987 11.3333ZM7.9987 11.3333C7.9987 10.5828 8.24676 9.89017 8.66536 9.33301V3.33333M7.9987 11.3333C7.9987 11.8836 8.13202 12.4027 8.36812 12.8601C7.80648 13.3345 6.50926 13.6667 4.9987 13.6667C2.97365 13.6667 1.33203 13.0697 1.33203 12.3333V3.33333M8.66536 3.33333C8.66536 4.06971 7.02374 4.66667 4.9987 4.66667C2.97365 4.66667 1.33203 4.06971 1.33203 3.33333M8.66536 3.33333C8.66536 2.59695 7.02374 2 4.9987 2C2.97365 2 1.33203 2.59695 1.33203 3.33333M1.33203 9.33333C1.33203 10.0697 2.97365 10.6667 4.9987 10.6667C6.45803 10.6667 7.71825 10.3567 8.30846 9.90785M8.66536 6.33333C8.66536 7.06971 7.02374 7.66667 4.9987 7.66667C2.97365 7.66667 1.33203 7.06971 1.33203 6.33333'
                                        stroke='#98A2B3'
                                        strokeWidth='1.4'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                                <span className='gray-700 text-sm medium'>{calculateCredits()} Export Credits / {toggle === 'monthly' ? 'month' : 'year'}</span>
                            </div>
                        </div>

                        <div className='column ai-centre gap12 width100'>
                            <button className='primaryButton width100 row jc-centre ai-centre gap8' onClick={purchasePlan}>
                                <span className='medium text-md'>Purchase Plan</span>
                                <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M3.82422 8.09961H13.1751' stroke='white' strokeWidth='1.33333' strokeLinecap='round' strokeLinejoin='round' />
                                    <path d='M8.5 3.42578L13.1754 8.09828L8.5 12.7708' stroke='white' strokeWidth='1.33333' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </button>
                            <span className='gray-700 regular text-sm'>or</span>
                            <button className='secondaryButton width100 row ai-centre gap8' onClick={register}>
                                <span className='primary-700 medium text-md'>Get started for free with</span>
                                <span className='gray-700 medium text-sm'>25 Credits</span>
                            </button>
                        </div>
                    </div>

                    <div className='column ai-start width50 lightGrey padding50 gap32 br32'>
                        <div className='width100 column gap24'>
                            <div className='width100 row jc-between ai-centre'>
                                <span className='gray-700 medium text-md'>Credit cost breakdown</span>
                                <span className='primary-700 medium text-xs pointer' onClick={openArticle}>
                                    About credits
                                </span>
                            </div>

                            <div className='width100 column gap16'>
                                <div className='width100 row ai-centre jc-between'>
                                    <div className='row ai-centre gap12'>
                                        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M8.66536 7.33333H11.8654C12.6121 7.33333 12.9855 7.33333 13.2707 7.47866C13.5216 7.60649 13.7255 7.81046 13.8534 8.06135C13.9987 8.34656 13.9987 8.71993 13.9987 9.46667V14M8.66536 14V4.13333C8.66536 3.3866 8.66536 3.01323 8.52004 2.72801C8.39221 2.47713 8.18824 2.27316 7.93735 2.14532C7.65214 2 7.27877 2 6.53203 2H4.13203C3.38529 2 3.01193 2 2.72671 2.14532C2.47583 2.27316 2.27185 2.47713 2.14402 2.72801C1.9987 3.01323 1.9987 3.3866 1.9987 4.13333V14M14.6654 14H1.33203M4.33203 4.66667H6.33203M4.33203 7.33333H6.33203M4.33203 10H6.33203'
                                                stroke={primary600}
                                                strokeWidth='1.4'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            />
                                        </svg>

                                        <div className='row ai-centre gap8'>
                                            <span className='gray-700 regular text-sm'>Export Standard Company</span>
                                        </div>
                                    </div>
                                    <span className='gray-700 medium text-sm'>1 credits</span>
                                </div>

                                <div className='width100 row ai-centre jc-between'>
                                    <div className='row ai-centre gap12'>
                                        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M13.9987 11.4667V14M8.66536 4.13333C8.66536 3.3866 8.66536 3.01323 8.52004 2.72801C8.39221 2.47713 8.18824 2.27316 7.93735 2.14532C7.65214 2 7.27877 2 6.53203 2H4.13203C3.38529 2 3.01193 2 2.72671 2.14532C2.47583 2.27316 2.27185 2.47713 2.14402 2.72801C1.9987 3.01323 1.9987 3.3866 1.9987 4.13333V14M14.6654 14H1.33203M4.33203 4.66667H6.33203M4.33203 7.33333H6.33203'
                                                stroke={primary600}
                                                strokeWidth='1.4'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            />
                                            <path
                                                d='M8.75 11V9.42105M8 10.2105H9.5M12.3 4L11.7797 5.42385C11.6951 5.6554 11.6528 5.77117 11.5871 5.86855C11.5288 5.95486 11.4571 6.03027 11.3751 6.09164C11.2826 6.16089 11.1726 6.20541 10.9527 6.29447L9.6 6.84211L10.9527 7.38974C11.1726 7.4788 11.2826 7.52332 11.3751 7.59257C11.4571 7.65394 11.5288 7.72935 11.5871 7.81566C11.6528 7.91304 11.6951 8.02881 11.7797 8.26036L12.3 9.68421L12.8203 8.26036C12.9049 8.02881 12.9472 7.91304 13.0129 7.81566C13.0712 7.72935 13.1429 7.65394 13.2249 7.59257C13.3174 7.52332 13.4274 7.4788 13.6473 7.38974L15 6.84211L13.6473 6.29447C13.4274 6.20541 13.3174 6.16089 13.2249 6.09164C13.1429 6.03027 13.0712 5.95486 13.0129 5.86855C12.9472 5.77117 12.9049 5.6554 12.8203 5.42385L12.3 4Z'
                                                stroke={primary600}
                                                strokeWidth='1.4'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            />
                                        </svg>

                                        <div className='row ai-centre gap8'>
                                            <span className='gray-700 regular text-sm'>Export Premium Company</span>
                                            <Tooltip icon={'normal'} text={'Premium companies are the very best prospects in the UK. Click ‘About Credits’ above to learn more'} />
                                        </div>
                                    </div>
                                    <span className='gray-700 medium text-sm'>3 credits</span>
                                </div>
                            </div>

                        </div>


                        <div className='width100 pricingExample'>
                            <span className='gray-700 text-xs regular'><strong>Example: </strong>Exporting 50 companies, including 10 premium companies, each with an email address, will cost 290 credits.</span>
                        </div>

                        <div className='column ai-start gap20'>
                            <span className='gray-700 medium text-md'>All plans include</span>
                            <div className='column ai-start gap12'>
                                <div className='row ai-centre gap12'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='gray-700 regular text-sm'>Profit & Revenue Estimations</span>
                                </div>
                                <div className='row ai-centre gap12'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='gray-700 regular text-sm'>Mail Merge Ready Exports</span>
                                </div>
                                <div className='row ai-centre gap12'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='gray-700 regular text-sm'>Smart FIltering & Advanced Insights</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {showPopUp && <IFramePopUp userP={userP} newPlan={selectedPlan} closeP={() => setShowPopUp(false)} success={successfulPayment} />}
        </div>
    )

}

export default PricingIFrame;