import { auth } from '..';
import '../styling/home.css'
import React, { useEffect, useState } from 'react';
import axios from "axios";
import PaymentComponent from '../components/PaymentComponent';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { onAuthStateChanged } from 'firebase/auth';
import axiosWithToken from 'axiosWithToken';


const Payments: React.FC = () => {

    // #region CONSTANTS & STATE VARIABLES
    const baseURL = process.env.REACT_APP_BASEURL;
    const baseURL2 = process.env.REACT_APP_BASEURL2;
    const localURL2 = process.env.REACT_APP_LOCALURL;

    const stripeLoaded: Promise<Stripe | null> = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY?.toString() || '');


    const [options, setOptions] = useState<any>(null)
    const [cusID, setCusID] = useState("")

    const [intentID, setIntentID] = useState("")
    const [subscription, setSubcription] = useState(false)
    const [submitCount, setSubmitCount] = useState(0)


    const appearance = {
        // labels: 'floating',
        variables: {
            // colorPrimary: '#E4D0A6',
            // colorBackground: '#242424',
            // colorTextPlaceholder: '#A0A0A0',
            // colorText: '#fff',
            // spacingUnit: '20px',
            // spacingGridRow: '5px',
            // spacingGridColumn: '5px',c
            // borderRadius: '25px',
            // fontLineHeight:'15px',
            // fontSizeBase:'16px'
        }

    };

    // #endregion


    // #region SHOW COMPONENTS

    // #endregion


    // #region WEB REQUESTS

    const createStripeCustomer = () => {
        return new Promise(async (resolve, reject) => {
            axios.get(baseURL + 'getCustomersStripeID', {
                params: {
                    authID: auth.currentUser?.uid
                }
            }).then((response) => {
                let cust = response.data.customerID
                setCusID(cust)
            })
        })
    }


    const createSetupIntent = () => {

        setSubcription(true)

        axiosWithToken.post(baseURL2 + 'api/stripe/setup-intent').then((response) => {

            const data = response.data;
            let setUpID = data.client_secret;
            setOptions({ clientSecret: setUpID, appearance: appearance });
            setIntentID("")
            setIntentID(setUpID)

        });

    }


    const createPaymentIntent = () => {

        setSubcription(false);

        axiosWithToken.post(baseURL2 + 'payment-intent', {
            amount: 50
        }).then((response) => {

            const data = response.data;

            let setUpID = data.paymentIntent.client_secret

            setOptions({ clientSecret: setUpID, appearance: appearance })

            setIntentID("")
            setIntentID(setUpID)

        });

    }


    const createSubscription = () => {

        setSubcription(false)

        axiosWithToken.post(localURL2 + 'api/stripe/subscriptions/create', {

            customerID: cusID,
            uid: auth.currentUser?.uid,
            billCycle: 'monthly',
            subscriptionOption: 'Medium'

        }).then((response) => {
            //Do we need to do anything here? Need to confirm with Simonas
            //const data = response.data;
        });

    }

    // #endregion


    // #region BUTTONS CLICKED

    // #endregion


    // #region OTHER
    const stripeResponse = (response: any) => {

        if (response.success && subscription) { createSubscription() }
    }
    // #endregion



    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                createStripeCustomer()
            }
        })
        return () => unsubscribe()

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="homeScreen">
            <h2>Payments</h2>

            {cusID !== "" && <div>
                <button className='testBtn' onClick={createPaymentIntent}>Make Payment</button>
                <button className='testBtn' onClick={createSetupIntent}>Create Subscription</button>
            </div>}

            {(intentID) &&
                <Elements stripe={stripeLoaded} options={options} >
                    <PaymentComponent stripeResponse={stripeResponse} submitCount={submitCount} payment={!subscription} />
                </Elements>
            }
            <button className='testBtn' onClick={() => setSubmitCount(submitCount + 1)} disabled={!intentID}>Submit</button>
        </div>
    )

}
export default Payments;