import "../styling/login.css";
import "../styling/constants/button.css";
import "../styling/constants/inputs.css";
import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../components/Mixpanel/Mixpanel";

interface VerifyEmailSuccessProps {
  showChoosePlan: any;
}

const VerifyEmailSuccess: React.FC<VerifyEmailSuccessProps> = ({ showChoosePlan }) => {

  // #region CONSTANTS & STATE VARIABLES
  const location = useLocation();
  const navigate = useNavigate();


  Mixpanel.track("Email Verified");
  const query = new URLSearchParams(location.search);
  const src = query.get('src')
  const isHarbourClub = (src === "harbourclub")
  const isBiz4Biz = (src === "biz4biz")
  // #endregion

  // #region SHOW COMPONENTS

  // #endregion

  // #region

  // #endregion

  // #region BUTTONS CLICKED

  const handleChoosePlan = () => {
    showChoosePlan(true);
    navigate(`../search`, { replace: true });
  }

  const handleNavigateSearch = () => {
    navigate(`../search`, { replace: true });
  }


  // #region OTHER

  // #endregion

  return (
    <div className='loginScreen'>
      <div className='flex flex-col justify-center items-center gap-6 rounded-2xl p-8 shadow-lg shadow-gray-400 z200 '>
        <div className='flex flex-col items-center gap-2'>

          <div className='row ai-centre gap12'>
            <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.8041 12H29.4298C33.4926 12 36.7861 15.4252 36.7861 19.6505C36.7861 21.6095 36.078 23.3966 34.9136 24.75C36.078 26.1034 36.7861 27.8905 36.7861 29.8495C36.7861 34.0748 33.4926 37.5 29.4298 37.5L13.9003 37.4997V27.2991H18.8041V22.2006H21.2562V22.1991H29.4298C29.4544 22.1991 29.479 22.1992 29.5035 22.1995C30.8237 22.1589 31.8819 21.0332 31.8819 19.6505C31.8819 18.242 30.7841 17.1003 29.4298 17.1003H18.8041V12ZM18.8044 32.3997V27.3009H29.4298C29.4544 27.3009 29.479 27.3008 29.5035 27.3005C30.8237 27.3411 31.8819 28.4668 31.8819 29.8495C31.8819 31.258 30.7841 32.3997 29.4298 32.3997H18.8044Z'
                fill='black'
              />
              <path
                d='M13.8998 17.1034H9V12.0031H13.9042V17.0981H18.804V22.1984H13.8998V17.1034Z'
                fill='black'
              />
            </svg>

            {(isHarbourClub || isBiz4Biz) && <span className='display-xs bold gray-900'>X</span>}

            {isHarbourClub && <img className='width48px' src='/assets/HC_Logo.png' alt='harborclubLogo' />}
            {isBiz4Biz && <img className='width48px' src='/assets/logoB4B.jpg' alt='harborclubLogo' />}
          </div>



          <div className='loginTitles'>
            <span className='display-xs semibold gray-900'>Your email has been verified</span>
          </div>
        </div>

        <div className='width500px flex flex-col text-center gap-1'>
          <span className='text-md medium gray-700'>
            You have successful vaildated your email address
          </span>
          <br />
          {!isHarbourClub && <span className='text-md medium gray-700'>
            Please close this tab and return to the previous Bizcrunch tab you had open to proceed and use the service.
          </span>}
          {isHarbourClub && <span className='text-md medium gray-700'>
            Please close this tab and return to the Harbour Club portal to proceed and use the service.
          </span>}
          <br />

        </div>

        <div className="width500px flex flex-col text-center gap-1">
          <span className="text-md medium gray-700">
            You have successful vaildated your email address
          </span>
          <br />
          {!isHarbourClub && (
            <span className="text-md medium gray-700">
              Please close this tab and return to the previous Bizcrunch tab you
              had open to proceed and use the service.
            </span>
          )}
          {isHarbourClub && (
            <span className="text-md medium gray-700">
              Please close this tab and return to the Harbour Club portal to
              proceed and use the service.
            </span>
          )}
          <br />
        </div>

        <div className="loginInputs gap-5 !text-center justify-center">
          <button className="loginBtn primaryButton" onClick={handleChoosePlan}>
            Choose your plan
          </button>

          <button
            className="loginBtn secondaryButton"
            onClick={handleNavigateSearch}
          >
            Start your search
          </button>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmailSuccess;