import "../../styling/billing.css";
import React from "react";

interface CurrentPlanTileProps {
  editPlan: () => void;
  unpausePlan: () => void;
  plan: any;
  user: any;
  addCredits?: () => void;
}

const CurrentPlanTile: React.FC<CurrentPlanTileProps> = ({
  editPlan,
  unpausePlan,
  plan,
  user,
  addCredits,
}) => {
  return (
    <div className="currentPlanTile">
      <div className="cptTopRow">
        <div className="cptTitleColumn">
          <div className="cptTitleRow">
            <span className="text-lg medium gray-900">
              {plan?.id === "free" ? "25" : plan?.Credits} credits{" "}
            </span>
            <div className="cptPlanBubble text-sm medium primary-700">
              {user.planPaused
                ? "Paused until " + new Date(user.planPaused).toDateString()
                : plan?.id === "free"
                ? "One-Off"
                : plan?.Frequency === "monthly"
                ? "Monthly"
                : "Yearly"}
            </div>
          </div>
        </div>
        <div className="cptMonthlyPrice">
          <div className="cptMonthlyPriceLeft">
            <span className="display-md semibold gray-900">£</span>
            <span className="display-lg semibold gray-900">
              {plan?.Frequency === "yearly" ? plan?.Rate * 12 : plan?.Rate}
            </span>
          </div>
          {user.planPaused && (
            <span className="text-md medium gray-600">Plan paused</span>
          )}
          {!user.planPaused && (
            <span className="text-md medium gray-600">{`per ${
              plan?.Frequency === "monthly" ? "month" : "year"
            }`}</span>
          )}
        </div>
      </div>

      <div className="cptCreditSection">
        <div className="cptTopRow">
          <span className="text-sm medium gray-900">{`${
            user.credits - user.usedCredits
          } of ${user.credits} export credits remaining`}</span>
        </div>

        <progress
          className="cptProgress"
          value={user.usedCredits}
          max={user.credits}
        />
      </div>

      <div className="cptTopRow centre">
        <div className="tooltip">
          <span className="tooltiptext button text-sm regular">
            Coming soon
          </span>
        </div>
        {user.planPaused && (
          <button
            className="secondaryButton text-sm semibold"
            onClick={unpausePlan}
          >
            Resume my subscription
          </button>
        )}
        {!user.planPaused && (
          <button
            className="secondaryButton text-sm semibold"
            onClick={editPlan}
          >
            Change Plan
          </button>
        )}
      </div>
    </div>
  );
};

export default CurrentPlanTile;
