import { auth } from "../..";
import "../../styling/saved.css";
import React, { useEffect, useState } from "react";
import CancelPlanPopUp from "./CancelPlanPopUp";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../Mixpanel/Mixpanel";
import Lottie from "lottie-react";
import { lottieAnimationUrl } from "components/utils/network/endpoints";
import axiosWithToken from "axiosWithToken";

interface PausePlanPopUpProps {
  close: () => void;
  cancel: () => void;
  subscription: any;
}

const PausePlanPopUp: React.FC<PausePlanPopUpProps> = ({ close, cancel, subscription }) => {


  const navigate = useNavigate();
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const [loading, setLoading] = useState<boolean>(false);
  const [openCancelPlanPopUp, setOpenCancelPlanPopUp] = useState<boolean>(false);
  const [openPausePlan, setOpenPausePlan] = useState<boolean>(false);
  const [pauseDuration, setPauseDuration] = useState<number>(1);

  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      const response = await fetch(lottieAnimationUrl);
      const data = await response.json();
      setAnimationData(data);
    };

    fetchAnimationData();
  }, []);

  if (!animationData) {
    return null;
  }
  // const localURL2 = process.env.REACT_APP_LOCALURL2;
  let user = JSON.parse(localStorage.getItem("user")!)

  const pausePlan = async () => {
    setLoading(true);
    try {
      Mixpanel.track("FeatureUsage", {
        "User ID": auth.currentUser?.uid,
        "Date": new Date().toISOString(),
        $email: auth?.currentUser?.email,
        $name: auth?.currentUser?.displayName,
        "Plan": user?.plan,
        "User Type": "Buyer",
        "Feature:": "Paused Subscription",
      });

      await axiosWithToken.post(
        `${baseURL2}api/stripe/subscriptions/${subscription.id}/pause`,
        { months: pauseDuration }
      );

      setLoading(false);
      navigate(0);
      close();
    } catch (error) {
      setLoading(false);
      console.error('Error pausing subscription:', error);
    }
  };

  return (
    <div className="tint">
      <div className="popUp pausePlan gap20 text-left">
        <div className="row jc-end width100">
          <img
            className="closeButton pointer"
            src="/assets/x-close.png"
            onClick={close}
            alt="close"
          />
        </div>
        {!openPausePlan && (
          <>
            <div className="sfpuMain">
              <span className="text-lg semibold gray-900">Wait! Before you go...</span>
              <span className="text-sm regular gray-600">
                By cancelling your BizCrunch subscription, you'll lose access to:
              </span>
              <ul className="text-sm regular gray-600">
                <li>- Remaining Credits at the end of your billing period</li>
                <li>- Previous Exports you've made</li>
                <li>- Saved Filters you've set up</li>
              </ul>
              <span>
                Good News! You can pause your subscription instead. This way, you keep all your data and can pick up right where you left off whenever you're ready.
              </span>
            </div>

            {!loading && (
              <div className="row width100 jc-between gap12">
                <button
                  className="primaryButton width100"
                  onClick={() => setOpenPausePlan(true)}
                  disabled={loading}
                >
                  Pause my subscription
                </button>
                <div className="tooltip width100">
                  <button
                    className="secondaryButton width100"
                    onClick={() => setOpenCancelPlanPopUp(true)}
                    disabled={loading}
                  >
                    Proceed to Cancel
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        {openPausePlan && (
          <div className="sfpuMain column mb-2">
            {!loading && (
              <>
                <span className="text-lg semibold gray-900">Select Pause Duration</span>
                <span className="text-sm regular gray-600">
                  How long would you like to pause your BizCrunch subscription?
                </span>
                <ul className="text-sm regular gray-600 column gap-4">
                  <li>
                    <div className="column width100">
                      <span className="text-sm medium gray-700">
                        Take a short break. We'll be here when you're ready to return.
                      </span>
                      <div className="radioRow">
                        <input
                          className="radio input-sm"
                          type="radio"
                          checked={pauseDuration === 1}
                          onChange={() => setPauseDuration(1)}
                        />
                        <span className="text-sm medium gray-700">Pause for 1 Month</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="column width100">
                      <span className="text-sm medium gray-700">
                        Need a bit more time? No problem, we'll keep your data safe.
                      </span>
                      <div className="radioRow">
                        <input
                          className="radio input-sm"
                          type="radio"
                          checked={pauseDuration === 2}
                          onChange={() => setPauseDuration(2)}
                        />
                        <span className="text-sm medium gray-700">Pause for 2 Months</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="column width100">
                      <span className="text-sm medium gray-700">
                        Taking an extended break? We'll be ready whenever you are.
                      </span>
                      <div className="radioRow">
                        <input
                          className="radio input-sm"
                          type="radio"
                          checked={pauseDuration === 3}
                          onChange={() => setPauseDuration(3)}
                        />
                        <span className="text-sm medium gray-700">Pause for 3 Months</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <span>
                  During your pause:
                  <ul className="text-sm regular gray-600">
                    <li>- You retain all your data, credits, exports, and saved filters.</li>
                    <li>- You won't be billed until you reactivate your subscription.</li>
                    <li>- You can reactivate anytime if you change your mind</li>
                  </ul>
                </span>
                <button className="primaryButton width100" onClick={close}>
                  Keep my subscription
                </button>
                <button
                  className="secondaryButton width100"
                  disabled={pauseDuration === 0}
                  onClick={pausePlan}
                >
                  Pause my subscription
                </button>
              </>
            )}

            {loading && (
              <div className="loader">
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoplay={true}
                  style={{ transform: 'scale(0.7)' }}
                />
              </div>
            )}
          </div>
        )}
        {openCancelPlanPopUp && (
          <CancelPlanPopUp
            close={() => setOpenCancelPlanPopUp(false)}
            cancel={cancel}
            subscription={subscription}
          />
        )}
      </div>
    </div>
  );
};

export default PausePlanPopUp;
