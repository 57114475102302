import '../../styling/billing.css';
import React from 'react';

interface Customer {
  email: string;
}

interface PaymentMethodTileProps {
  editPayment: () => void;
  loading?: boolean;
  paymentMethod: any | null;
  customer: Customer;
}

const PaymentMethodTile: React.FC<PaymentMethodTileProps> = ({ editPayment, loading, paymentMethod, customer }) => {
  // Constants and variables
  const provider = paymentMethod
    ? paymentMethod.card.networks.available[0].charAt(0).toUpperCase() + paymentMethod.card.networks.available[0].slice(1)
    : null;
  const providerImage = provider === 'Visa' ? '/assets/visa.png' : '/assets/mastercard.png';

  return (
    <div className="currentPlanTile">
      <div className="cptTopRow">
        <div className="cptTitleColumn">
          <div className="cptTitleRow">
            <span className="text-lg medium gray-900">Payment method</span>
          </div>
          <span className="text-sm regular gray-600">Change how you pay for your plan.</span>
        </div>
      </div>

      {/* Payment method details */}
      {paymentMethod !== null && (
        <div className="cptCardSection">
          <div className="cptCardSectionLeft">
            <div className="cptCardProviderImg">
              <img src={providerImage} alt="cardProvider" />
            </div>
            <div className="cptCardDetails">
              <span className="text-sm medium gray-700">{`${provider} ending in ${paymentMethod.card.last4}`}</span>
              <span className="text-sm regular gray-600">{`Expiry ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</span>
              <div className="cptCardEmail">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M1.3335 4.66602L6.77678 8.47631C7.21756 8.78486 7.43795 8.93913 7.67767 8.99889C7.88943 9.05167 8.1109 9.05167 8.32265 8.99889C8.56238 8.93913 8.78277 8.78486 9.22355 8.47631L14.6668 4.66602M4.5335 13.3327H11.4668C12.5869 13.3327 13.147 13.3327 13.5748 13.1147C13.9511 12.9229 14.2571 12.617 14.4488 12.2407C14.6668 11.8128 14.6668 11.2528 14.6668 10.1327V5.86602C14.6668 4.74591 14.6668 4.18586 14.4488 3.75803C14.2571 3.38171 13.9511 3.07575 13.5748 2.884C13.147 2.66602 12.5869 2.66602 11.4668 2.66602H4.5335C3.41339 2.66602 2.85334 2.66602 2.42552 2.884C2.04919 3.07575 1.74323 3.38171 1.55148 3.75803C1.3335 4.18586 1.3335 4.74591 1.3335 5.86602V10.1327C1.3335 11.2528 1.3335 11.8128 1.55148 12.2407C1.74323 12.617 2.04919 12.9229 2.42552 13.1147C2.85334 13.3327 3.41339 13.3327 4.5335 13.3327Z"
                    stroke="#98A2B3"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-sm regular gray-600">{customer.email}</span>
              </div>
            </div>
          </div>
          <button className="secondaryButton" onClick={editPayment}>
            Edit
          </button>
        </div>
      )}

      {/* No payment method added */}
      {paymentMethod === null && (
        <div className="cptCardSection">
          <div className="cptCardSectionLeft">
            <div className="cptCardDetails">
              <span className="text-sm medium gray-700">No payment method added</span>
            </div>
          </div>
          <button className="secondaryButton" onClick={editPayment}>
            Add
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodTile;
