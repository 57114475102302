import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { lottieAnimationUrl } from 'components/utils/network/endpoints';
import axiosWithToken from 'axiosWithToken';

interface ConfirmPlanPopUpProps {
  close?: any;
  plan: any;
  paymentMethod: any;
  dismissNewPlan?: any;
  editPayment: () => void;
  currentSubscription: any;
  complete: any;
  iframe?: boolean;
}

const ConfirmPlanPopUp: React.FC<ConfirmPlanPopUpProps> = ({ close, plan, paymentMethod, dismissNewPlan, editPayment, currentSubscription, complete, iframe }) => {


  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponInput, setCouponInput] = useState("");
  const [coupon, setCoupon] = useState<any>();
  const [animationData, setAnimationData] = useState<any>(null);

  // const localURL2 = process.env.REACT_APP_LOCALURL2;

  const rate = plan.Frequency === "monthly" ? plan.Rate : plan.Rate * 12;

  const provider = paymentMethod ? paymentMethod.card.networks.available[0].charAt(0).toUpperCase() + paymentMethod.card.networks.available[0].slice(1) : "";
  const providerImage = paymentMethod ? provider === 'Visa' ? '/assets/visa.png' : '/assets/mastercard.png' : ""


  useEffect(() => {
    const fetchAnimationData = async () => {
      const response = await fetch(lottieAnimationUrl);
      const data = await response.json();
      setAnimationData(data);
    };

    fetchAnimationData();
  }, []);

  if (!animationData) {
    return null;
  }


  const cancelClicked = () => {
    dismissNewPlan();
    close();
  }

  const confirmClicked = () => {
    if (!currentSubscription) {
      createSubscription()
    }
    else {
      updateSubscription()
    }
  }

  const applyCoupon = async () => {
    setLoading(true);
    setError(false);

    try {
      const response = await axiosWithToken.get(`${baseURL2}api/stripe/search-coupon`, {
        params: {
          coupon: couponInput,
          plan: plan.id
        }
      });

      setLoading(false);

      if (response.data.error) {
        setError(true);
      } else {
        setCoupon(response.data.coupon);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error applying coupon:', error);
      setError(true);
    }
  };


  const createSubscription = async () => {
    setLoading(true);
    const requestData: { priceId: any; coupon?: string, planId: string } = {
      priceId: plan.StripePlan,
      planId: plan.id,
    };
    if (coupon) {
      requestData.coupon = coupon.id;
    }
    try {
      const response = await axiosWithToken.post(`${baseURL2}api/stripe/subscriptions/create`, requestData);
      if (response.data.error) {
        throw new Error(response.data.error)
      }

      setLoading(false);
      complete(response.data.subscription);

      // Rewardful code
      // let user = JSON.parse(localStorage.getItem('user'))
      // window.rewardful('convert', { email: user.email });

    } catch (error) {
      setLoading(false);
      console.error("Error creating subscription:", error);
    }
  };


  const updateSubscription = async () => {
    setLoading(true);

    try {
      const response = await axiosWithToken.post(`${baseURL2}api/stripe/subscriptions/${currentSubscription.id}/update`, {
        subscriptionId: currentSubscription.id,
        newPlanId: plan.id,
        newPriceId: plan.StripePlan,
      });
      if (response.data.error) {
        throw new Error(response.data.error);
      }

      setLoading(false);
      complete(response.data.subscription);

    } catch (error) {
      setLoading(false);
      console.error("Error updating subscription:", error);
    }
  };

  const calculateDiscount = () => {

    var rate

    if (coupon.percent_off !== null) {
      rate = plan.Frequency === "monthly" ? plan.Rate : plan.Rate * 12
      rate = rate * (coupon.percent_off / 100)

      return rate
    }

    else if (coupon.amount_off !== null) {
      rate = coupon.amount_off
      return rate
    }
    else {
      return 0
    }


  }

  const calculateVAT = () => {
    var rate = plan.Frequency === "monthly" ? plan.Rate : plan.Rate * 12
    if (coupon) {
      rate = rate - calculateDiscount()
    }

    return (rate * .2).toFixed(2)
  }

  const calculateTotal = () => {
    var rate = plan.Frequency === "monthly" ? plan.Rate : plan.Rate * 12
    if (coupon) {
      rate = rate - calculateDiscount()
    }
    rate = (rate + (rate * .2)).toFixed(2)

    return rate
  }


  return (

    <div>
      {!iframe && <div className='tint'>
        <div className='popUp confirmPlan'>
          <div className='row jc-between width100 ai-centre'>
            <span className='text-lg semibold gray-900'>New plan details</span>
            <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close' />
          </div>

          <div className='sfpuMain inner'>
            <span className='text-sm medium gray-700'>Discount Code</span>
            <div className='row gap12 ai-centre width100'>
              <input className={`textInput text-md gray-90`} type="text" placeholder={"Enter discount code"} value={couponInput} onChange={(e) => setCouponInput(e.target.value)} disabled={coupon} />
              <button className='primaryButton' disabled={coupon || couponInput === ""} onClick={applyCoupon}>{coupon ? "Applied" : "Apply"}</button>
            </div>
            {coupon && <span className='text-xs primary-700'>Discount Applied!</span>}
            {error && <span className='text-xs error-500'>Invalid discount code</span>}
          </div>

          <div className='column width100 gap12'>
            <div className='row jc-between width100'>
              <span className='text-md semibold gray-600'>{plan.Name}</span>
              <span className='text-md regular gray-600'>{`£${rate}`}</span>
            </div>

            {coupon && <div className='row jc-between width100'>
              <span className='text-md regular gray-600'>Discount</span>
              <span className='text-md regular gray-600'>{`- £${calculateDiscount()}`}</span>
            </div>}

            <div className='row jc-between width100'>
              <span className='text-md regular gray-600'>VAT</span>
              <span className='text-md regular gray-600'>{`£${calculateVAT()}`}</span>
            </div>

            <div className='greyLine' />

            <div className='row jc-between width100'>
              <span className='text-md semibold gray-600'>Due Now</span>
              <span className='text-md semibold gray-600'>{`£${calculateTotal()}`}</span>
            </div>

            <div className='greyLine' />

          </div>

          <div className='confirmPayment'>
            <div className='cptCardProviderImg'>
              {paymentMethod && <img src={providerImage} alt='cardProvider' />}
            </div>
            <div className='cptCardDetails'>
              {paymentMethod && <span className='text-sm medium gray-700'>{`${provider} ending in ${paymentMethod.card.last4}`}</span>}
              {paymentMethod && <span className='text-sm regular gray-600'>{`Expiry ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</span>}
              {!paymentMethod && <span className='text-sm medium gray-700'>No payment method added</span>}
              <span className='text-sm semibold primary-700 pointer' onClick={editPayment}>Edit</span>
            </div>
          </div>

          {loading && <div className='loader'>
            <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              style={{ transform: 'scale(0.7)' }}

            /> </div>
          }

          {!loading && <div className='row width100 jc-centre gap20'>
            <button className='secondaryButton width50' onClick={cancelClicked}>Cancel</button>
            <button className='primaryButton width50' onClick={confirmClicked}>Confirm</button>
          </div>}

        </div>
      </div>}
      {iframe && <div className='column gap12 ai-centre'>
        <div className='row jc-between width100 ai-centre'>
          <span className='text-lg semibold gray-900'>New plan details</span>
        </div>

        <div className='sfpuMain inner'>
          <span className='text-sm medium gray-700'>Discount Code</span>
          <div className='row gap12 ai-centre width100'>
            <input className={`textInput text-md gray-90`} type="text" placeholder={"Enter discount code"} value={couponInput} onChange={(e) => setCouponInput(e.target.value)} disabled={coupon} />
            <button className='primaryButton' disabled={coupon || couponInput === ""} onClick={applyCoupon}>{coupon ? "Applied" : "Apply"}</button>
          </div>
          {coupon && <span className='text-xs primary-700'>Discount Applied!</span>}
          {error && <span className='text-xs error-500'>Invalid discount code</span>}
        </div>

        <div className='column width100 gap12'>
          <div className='row jc-between width100'>
            <span className='text-md semibold gray-600'>{plan.Name}</span>
            <span className='text-md regular gray-600'>{`£${rate}`}</span>
          </div>

          {coupon && <div className='row jc-between width100'>
            <span className='text-md regular gray-600'>Discount</span>
            <span className='text-md regular gray-600'>{`- £${calculateDiscount()}`}</span>
          </div>}

          <div className='row jc-between width100'>
            <span className='text-md regular gray-600'>VAT</span>
            <span className='text-md regular gray-600'>{`£${calculateVAT()}`}</span>
          </div>

          <div className='greyLine' />

          <div className='row jc-between width100'>
            <span className='text-md semibold gray-600'>Due Now</span>
            <span className='text-md semibold gray-600'>{`£${calculateTotal()}`}</span>
          </div>

          <div className='greyLine' />

        </div>

        <div className='confirmPayment'>
          <div className='cptCardProviderImg'>
            {paymentMethod && <img src={providerImage} alt='cardProvider' />}
          </div>
          <div className='cptCardDetails'>
            {paymentMethod && <span className='text-sm medium gray-700'>{`${provider} ending in ${paymentMethod.card.last4}`}</span>}
            {paymentMethod && <span className='text-sm regular gray-600'>{`Expiry ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}</span>}
            {!paymentMethod && <span className='text-sm medium gray-700'>No payment method added</span>}
            <span className='text-sm semibold primary-700 pointer' onClick={editPayment}>Edit</span>
          </div>
        </div>

        {loading && <div className='loader'>
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ transform: 'scale(0.7)' }}
          /></div>
        }

        {!loading && <div className='row width100 jc-centre gap20'>
          <button className='secondaryButton width50' onClick={cancelClicked}>Cancel</button>
          <button className='primaryButton width50' onClick={confirmClicked} disabled={!paymentMethod}>Confirm</button>
        </div>}

      </div>}
    </div>

  )

}

export default ConfirmPlanPopUp;