import React, { } from 'react';
import '../styling/constants/divs.css'

const PoweredByBC : React.FC = () => {

// #region CONSTANTS & STATE VARIABLES
 

// #endregion




// #region SHOW COMPONENTS

// #endregion


// #region WEB REQUESTS

// #endregion


// #region BUTTONS CLICKED

   
// #endregion


// #region OTHER

// #endregion    

    

    return(
        <div className="poweredByBC">

            <img className="poweredByBCImg" src='/assets/poweredByBC.png' alt='poweredByBC'/>

        </div>
    )

}

export default PoweredByBC;