export const FILTER_OPTIONS = [
    {
        id: 0, title: "Key filters", icon: "/assets/logoLetter.png", options: [
            {
                id: "0A", title: "Key Filters", options: [
                    { id: "0A1", title: "Already Exported", type: "alreadyExported" },
                    {
                        id: "4B", title: "Contact", type: 'multiple', tooltip: 'Show results that have a direct PSC email address', values: [
                            "Companies with direct PSC email",
                        ]
                    },
                    { id: "3C1", title: "Net Profit %", type: "slider", hasEstimate: true },
                    { id: "3C2", title: "Net Profit", type: "range", subType: "financial", hasEstimate: true },
                    { id: "3B1", title: "Debt Ratio", type: "slider", tooltip: "Filter by the amount of debt vs. asset value a company has" },
                    { id: "3B2", title: "Fixed Tangible Asset : Net Profit Ratio", type: "slider" },


                ]
            }
        ]
    },
    { id: 1, title: "Smart acquire filters", icon: "/assets/safIcon.png", options: [{ id: "1A", title: "Smart acquire filters", type: "multiple", subType: "smartFilter", values: ["Leverageable", "Profitable", "Established", "Retiring Owner", "Simple ownership"], tooltips: ["Has available borrowing capacity against its existing assets. Low debt & high proportion of fixed assets", "Reasonable net profit margins filed or inferred through retained earnings", "Private limited company, incorporated at least 20 years ago", "All majority shareholders are coming up to retirement age", "Small number of PSCs and single layer group structure"] }] },
    {
        id: 2, title: "Company", icon: "/assets/companyIcon.png", options: [
            { id: "2A", title: "Company name", type: "multiple", subType: "companyName", placeholder: "Enter company name" },
            { id: "2B", title: "Company number", type: "text", placeholder: "Enter company number" },
            { id: "2C", title: "SIC Industry name", type: "multiple", subType: "sic", placeholder: "Enter SIC Code" },
            { id: "2D", title: "Incorporation date", type: "dateRange" },
            // {id:"2E",title:"Company type",type:"multiple",subType:"companyType"},
            { id: "2F", title: "Company status", type: "multiple", values: ["Active", "Dormant", "Dissolved", "In Liquidiation", "In Administation", "In Receivership", "In Voluntary Arrangement"] },
            { id: "2G", title: "Account filing month", type: 'dropdown' },
            { id: "2H", title: "Location", type: "multiple", subType: 'location' },

            // {id:"2I",title:"KeyWord",type:"multiple", placeholder:"Keywords",subType:"text"},

        ]
    },
    {
        id: 3, title: "Financials", icon: "/assets/financialsIcon.png", options: [
            {
                id: "3A", title: "Balance Sheet", options: [
                    { id: "3A1", title: "Current Assets", type: "range", subType: "financial" },
                    { id: "3A2", title: "Fixed Assets", type: "range", subType: "financial" },
                    { id: "3A3", title: "Fixed Tangible Assets", type: "range", subType: "financial" },
                    { id: "3A4", title: "Total Assets", type: "range", subType: "financial" },
                    { id: "3A5", title: "Current Liabilities", type: "range", subType: "financial" },
                    { id: "3A6", title: "Long-term Liabilities", type: "range", subType: "financial" },
                    { id: "3A7", title: "Net Assets", type: "range", subType: "financial" }
                ]
            },
            {
                id: "3B", title: "Smart Metrics", options: [
                    { id: "3B1", title: "Debt Ratio", type: "slider", tooltip: "Filter by the amount of debt vs. asset value a company has" },
                    { id: "3B2", title: "Fixed Tangible Asset : Net Profit Ratio", type: "slider" },
                ]
            },
            {
                id: "3C", title: "P&L", options: [
                    { id: "3C1", title: "Net Profit %", type: "slider", hasEstimate: true },
                    { id: "3C2", title: "Net Profit", type: "range", subType: "financial", hasEstimate: true },
                    { id: "3C3", title: "Turnover", type: "range", subType: "financial", isRevenue: true },
                ]
            }]
    },
    {
        id: 4, title: "People", icon: "/assets/peopleIcon.png", options: [
            {
                id: "4A", title: "Ownership", options: [
                    { id: "4A1", title: "No. of PSCs", type: "range", max: 10 },
                    { id: "4A2", title: "Max Parent companies", type: "radio", values: ['0', '1', '2', '3'], tooltip: "Filter by the number of 'parent' entities that own a company" },
                    { id: "4A3", title: "People to contact", type: "multiple", values: ['Majority Shareholders', '25%+ Shareholders', 'All PSCs'] },
                    { id: "4A4", title: "Majority Shareholder Age", type: "range" },
                ]
            },
            {
                id: "4B", title: "Contact", type: 'multiple', tooltip: 'Show results that have a direct PSC email address', values: [
                    "Companies with direct PSC email",
                    // "Contacts have phone number",
                    // "Contacts have Linkedin"
                ]
                // [{id:"4B1",title:"Companies have business email", type:"add"},
                // {id:"4B2",title:"Contacts have phone number", type:"add"},
                // {id:"4B3",title:"Contacts have Linkedin", type:"add"},]
            },
            {
                id: "4C", title: "Employees", tooltip: "Filter by the number of employees a company has", options: [
                    { id: "4C1", title: "No. of employees", type: "range" },
                ]
            },

        ]
    },

    { id: 5, title: "Saved Filters", icon: "/assets/savedFilters.png", options: [{ id: "5A", title: "Saved filters", type: "multiple", subType: "savedFilter", values: [] }] }
]




export const REVENUE_OPTIONS = [
    { value: 250000, title: '£250K' },
    { value: 500000, title: '£500K' },
    { value: 1000000, title: '£1M' },
    { value: 2500000, title: '£2.5M' },
    { value: 5000000, title: '£5M' },
    { value: 7500000, title: '£7.5M' },
    { value: 10000000, title: '£10M' },
    { value: 12500000, title: '£12.5M' },
    { value: 15000000, title: '£15M' },
    { value: 20000000, title: '£20M' },
    { value: 25000000, title: '£25M' },
    { value: 30000000, title: '£30M' },
    { value: 35000000, title: '£35M' },
    { value: 40000000, title: '£40M' },
    { value: 45000000, title: '£45M' },
    { value: 50000000, title: '£50M' },
    { value: 60000000, title: '£60M' },
    { value: 70000000, title: '£70M' },
    { value: 80000000, title: '£80M' },
    { value: 90000000, title: '£90M' },
    { value: 100000000, title: '£100M' },

]



export const FINANCIAL_OPTIONS1 = [
    { value: 0, title: '£0' },
    { value: 25000, title: '£25K' },
    { value: 50000, title: '£50K' },
    { value: 75000, title: '£75K' },
    { value: 100000, title: '£100K' },
    { value: 150000, title: '£150K' },
    { value: 250000, title: '£250K' },
    { value: 350000, title: '£350K' },
    { value: 500000, title: '£500K' },
    { value: 750000, title: '£750K' },
    { value: 1000000, title: '£1M' },
    { value: 1250000, title: '£1.25M' },
    { value: 1500000, title: '£1.5M' },
    { value: 2000000, title: '£2M' },
    { value: 2500000, title: '£2.5M' },
    { value: 3000000, title: '£3M' },
    { value: 4000000, title: '£4M' },
    { value: 5000000, title: '£5M' },
    { value: 6000000, title: '£6M' },
    { value: 7500000, title: '£7.5M' },
    { value: 10000000, title: '£10M' },
    { value: 12500000, title: '£12.5M' },
    { value: 15000000, title: '£15M' },
    { value: 17500000, title: '£17.5M' },
    { value: 20000000, title: '£20M' },
    { value: 25000000, title: '£25M' },
    { value: 30000000, title: '£30M' },
    { value: 35000000, title: '£35M' },
    { value: 40000000, title: '£40M' },
    { value: 45000000, title: '£45M' },
    { value: 50000000, title: '£50M' },

]


export const FINANCIAL_OPTIONS2 = [
    { value: 0, title: '£0' },
    { value: 50000, title: '£50K' },
    { value: 75000, title: '£75K' },
    { value: 100000, title: '£100K' },
    { value: 150000, title: '£150K' },
    { value: 250000, title: '£250K' },
    { value: 350000, title: '£350K' },
    { value: 500000, title: '£500K' },
    { value: 750000, title: '£750K' },
    { value: 1000000, title: '£1M' },
    { value: 1250000, title: '£1.25M' },
    { value: 1500000, title: '£1.5M' },
    { value: 2000000, title: '£2M' },
    { value: 2500000, title: '£2.5M' },
    { value: 3000000, title: '£3M' },
    { value: 4000000, title: '£4M' },
    { value: 5000000, title: '£5M' },
    { value: 6000000, title: '£6M' },
    { value: 7500000, title: '£7.5M' },
    { value: 10000000, title: '£10M' },
    { value: 12500000, title: '£12.5M' },
    { value: 15000000, title: '£15M' },
    { value: 17500000, title: '£17.5M' },
    { value: 20000000, title: '£20M' },
]


export const FINANCIAL_OPTIONS3 = [
    { value: 0, title: '£0' },
    { value: 25000, title: '£25K' },
    { value: 50000, title: '£50K' },
    { value: 75000, title: '£75K' },
    { value: 100000, title: '£100K' },
    { value: 150000, title: '£150K' },
    { value: 250000, title: '£250K' },
    { value: 350000, title: '£350K' },
    { value: 500000, title: '£500K' },
    { value: 750000, title: '£750K' },
    { value: 1000000, title: '£1M' },
    { value: 1250000, title: '£1.25M' },
    { value: 1500000, title: '£1.5M' },
    { value: 2000000, title: '£2M' },
    { value: 2500000, title: '£2.5M' },
    { value: 3000000, title: '£3M' },
    { value: 4000000, title: '£4M' },
    { value: 5000000, title: '£5M' },
    { value: 6000000, title: '£6M' },
    { value: 7500000, title: '£7.5M' },
    { value: 10000000, title: '£10M' },
    { value: 12500000, title: '£12.5M' },
    { value: 15000000, title: '£15M' },
    { value: 17500000, title: '£17.5M' },
    { value: 20000000, title: '£20M' },
]




export const AGE_OPTIONS = [
    { value: 20, title: '20' },
    { value: 30, title: '30' },
    { value: 40, title: '40' },
    { value: 50, title: '50' },
    { value: 60, title: '60' },
    { value: 70, title: '70' },
    { value: 80, title: '80' },
    { value: 90, title: '90' },
]



export const PSC_OPTIONS = [
    { value: 1, title: '1' },
    { value: 2, title: '2' },
    { value: 3, title: '3' },
    { value: 4, title: '4' },
    { value: 5, title: '5' },
    { value: 6, title: '6' },
    { value: 7, title: '7' },
    { value: 8, title: '8' },
    { value: 9, title: '9' },
    { value: 10, title: '10' },


]

export const NUM_EMPLOYEE_OPTIONS = [
    { value: 1, title: '1' },
    { value: 5, title: '5' },
    { value: 10, title: '10' },
    { value: 20, title: '20' },
    { value: 50, title: '50' },
    { value: 100, title: '100' },
    { value: 200, title: '200' },
    { value: 500, title: '500' },
    { value: 1000, title: '1000' },
]


export const RADIUS_OPTIONS = [
    { value: 1, title: '1 mile' },
    { value: 5, title: '5 miles' },
    { value: 25, title: '25 miles' },
    { value: 50, title: '50 miles' },
    { value: 100, title: '100 miles' },
]

export const DATE_RANGE_OPTIONS = [
    { value: 1800, title: "Pre 1980" },
    { value: 1980, title: "1980" },
    { value: 1981, title: "1981" },
    { value: 1982, title: "1982" },
    { value: 1983, title: "1983" },
    { value: 1984, title: "1984" },
    { value: 1985, title: "1985" },
    { value: 1986, title: "1986" },
    { value: 1987, title: "1987" },
    { value: 1988, title: "1988" },
    { value: 1989, title: "1989" },
    { value: 1990, title: "1990" },
    { value: 1991, title: "1991" },
    { value: 1992, title: "1992" },
    { value: 1993, title: "1993" },
    { value: 1994, title: "1994" },
    { value: 1995, title: "1995" },
    { value: 1996, title: "1996" },
    { value: 1997, title: "1997" },
    { value: 1998, title: "1998" },
    { value: 1999, title: "1999" },
    { value: 2000, title: "2000" },
    { value: 2001, title: "2001" },
    { value: 2002, title: "2002" },
    { value: 2003, title: "2003" },
    { value: 2004, title: "2004" },
    { value: 2005, title: "2005" },
    { value: 2006, title: "2006" },
    { value: 2007, title: "2007" },
    { value: 2008, title: "2008" },
    { value: 2009, title: "2009" },
    { value: 2010, title: "2010" },
    { value: 2011, title: "2011" },
    { value: 2012, title: "2012" },
    { value: 2013, title: "2013" },
    { value: 2014, title: "2014" },
    { value: 2015, title: "2015" },
    { value: 2016, title: "2016" },
    { value: 2017, title: "2017" },
    { value: 2018, title: "2018" },
    { value: 2019, title: "2019" },
    { value: 2020, title: "2020" },
];