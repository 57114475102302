import "../../../../styling/filters.css";
import React, { useEffect, useState, useContext } from "react";
import { ParentContext } from "../../../constants/ParentContext";
import { Mixpanel } from "../../../Mixpanel/Mixpanel";

interface TextFilterProps {
  filter: any;
  addedFilters: any;
  apply: any;
}

const TextFilter: React.FC<TextFilterProps> = ({ filter, addedFilters, apply }) => {
  // #region CONSTANTS & STATE VARIABLES
  let user = JSON.parse(localStorage.getItem("user")!)

  const [text, setText] = useState("");
  const context = useContext<any>(ParentContext);
  const isBiz = context.isBiz4Biz || context.isHarbour ? false : true;
  // #endregion
  useEffect(() => {
    setText("");

    addedFilters?.forEach((element: any) => {
      if (element.id === filter.id) {
        setText(element.value);
      }
    });
  }, [filter.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled = () => {
    if (text === "") {
      return true;
    } else {
      return false;
    }
  };

  // #region SHOW COMPONENTS

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED
  const applyFilter = () => {

    Mixpanel.track("FeatureUsage", {
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      "User Type": "Buyer",
      "User ID": user?.uid,
      "Date": new Date().toISOString(),
      "Feature": `Applied ${filter.title} Filter`
    });

    apply(filter, text);
  };
  // #endregion

  // #region OTHER

  // #endregion

  return (
    <div className="textFilter padding3">
      <input
        className="textInput search text-md regular gray-900"
        type="text"
        placeholder={filter.placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button className="secondaryButton applyFilterBtn" onClick={applyFilter}>
        <img
          className="secondaryButtonIcon"
          src={
            isDisabled()
              ? "/assets/grey-plus.png"
              : isBiz
                ? "/assets/red-plus.png"
                : "/assets/blue-plus.png"
          }
          alt="plus"
        />
        <span
          className={`text-xs semibold ${isDisabled() ? "gray-300" : "primary-700"
            }`}
        >
          Apply filter
        </span>
      </button>
    </div>
  );
}

export default TextFilter;