import "../../../../styling/filters.css";
import React, { useEffect, useState, useContext } from "react";
import { ParentContext } from "../../../constants/ParentContext";
import { Mixpanel } from "../../../Mixpanel/Mixpanel";
import * as constants from "../../../../constants";

interface DateRangeFilterProps {
  filter: any;
  addedFilters: any;
  apply: any;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  filter,
  addedFilters,
  apply
}) => {
  // #region CONSTANTS & STATE VARIABLES
  const context = useContext<any>(ParentContext);
  const isBiz = context?.isBiz4Biz || context?.isHarbour ? false : true;

  const [min, setMin] = useState("");
  const [max, setMax] = useState("");

  let user = JSON.parse(localStorage.getItem("user")!)


  const options = constants.DATE_RANGE_OPTIONS;

  // #endregion

  useEffect(() => {
    addedFilters.forEach((element: any) => {
      if (element.id === filter.id) {
        setMin(element.value.min);
        setMax(element.value.max);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled = () => {
    if ((min === null || min === "") && (max === null || max === "")) {
      return true;
    } else if (
      min !== null &&
      min !== "" &&
      max !== null &&
      max !== "" &&
      +max < +min
    ) {
      return true;
    } else {
      return false;
    }
  };

  // #region SHOW COMPONENTS

  const addOptions = (type: any) => {
    let compArray: any = [];
    options.forEach((element: any) => {
      compArray.push(
        <option
          value={element.value}
          title={element.title}
          disabled={isOptionDisabled(type, element.value)}
          key={element.value}
        >
          {element.title}{" "}
        </option>
      );
    });
    return compArray;
  };

  const isOptionDisabled = (type: string, value: number) => {
    if (type === "max" && min !== "" && value <= +min) {
      return true;
    } else if (type === "min" && max !== "" && value >= +max) {
      return true;
    } else {
      return false;
    }
  };

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED
  const applyFilter = () => {

    Mixpanel.track("FeatureUsage", {
      $name: user?.name,
      $email: user?.email,
      "User Type": "Buyer",
      "Plan": user?.plan,
      "User ID": user?.uid,
      "Date": new Date().toISOString(),
      "Feature": `Applied ${filter.title} Filter`
    });

    let value = { min: min, max: max };
    apply(filter, value);
  };

  // #endregion

  // #region OTHER

  // #endregion

  return (
    <div className="textFilter padding3">
      <div className="revenueInputRow">
        <select
          className="textInput date text-md regular gray-900 pointer"
          value={min}
          onChange={(e) => setMin(e.target.value)}
        >
          <option value="" disabled hidden>
            Earliest Year
          </option>
          {addOptions("min")}
        </select>
        <span className="text-sm regular gray-500">to</span>
        <select
          className="textInput date text-md regular gray-900 pointer"
          value={max}
          onChange={(e) => setMax(e.target.value)}
        >
          <option value="" disabled hidden>
            Latest Year
          </option>
          {addOptions("max")}
        </select>
      </div>
      <button className="secondaryButton applyFilterBtn" onClick={applyFilter}>
        <img
          className="secondaryButtonIcon"
          src={
            isDisabled()
              ? "/assets/grey-plus.png"
              : isBiz
                ? "/assets/red-plus.png"
                : "/assets/blue-plus.png"
          }
          alt="plus"
        />
        <span
          className={`text-xs semibold ${isDisabled() ? "gray-300" : "primary-700"
            }`}
        >
          Apply filter
        </span>
      </button>
    </div>
  );
}

export default DateRangeFilter;