export const themeSetter = (isBiz: boolean): void => {

  document.documentElement.style.setProperty('--map-marker-hover', `url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M6.00521 13.6673C3.83791 11.5 0.671875 9.01232 0.671875 5.66732C0.671875 2.7218 3.05969 0.333984 6.00521 0.333984C8.95073 0.333984 11.3385 2.7218 11.3385 5.66732C11.3385 9.01232 8.17251 11.5 6.00521 13.6673ZM6.00521 7.66732C7.10978 7.66732 8.00521 6.77189 8.00521 5.66732C8.00521 4.56275 7.10978 3.66732 6.00521 3.66732C4.90064 3.66732 4.00521 4.56275 4.00521 5.66732C4.00521 6.77189 4.90064 7.66732 6.00521 7.66732Z' fill='%23E31B53'/%3E%3C/svg%3E%0A")`);

  if (!isBiz) {
    document.documentElement.style.setProperty('--primary-25', '#F5FBFF');
    document.documentElement.style.setProperty('--primary-50', '#F0F9FF');
    document.documentElement.style.setProperty('--primary-100', '#E0F2FE');
    document.documentElement.style.setProperty('--primary-200', '#B9E6FE');
    document.documentElement.style.setProperty('--primary-300', '#7CD4FD');
    document.documentElement.style.setProperty('--primary-400', '#36BFFA');
    document.documentElement.style.setProperty('--primary-500', '#0BA5EC');
    document.documentElement.style.setProperty('--primary-600', '#0086C9');
    document.documentElement.style.setProperty('--primary-700', '#026AA2');
    document.documentElement.style.setProperty('--primary-800', '#065986');
    document.documentElement.style.setProperty('--primary-900', '#0B4A6F');
    document.documentElement.style.setProperty('--primary-950', '#062C41');
    document.documentElement.style.setProperty('--bizcrunch-gradient', 'linear-gradient(90deg, #3DAEDF 0%, #0078AC 50%, #182059 100%)');
    document.documentElement.style.setProperty('--bizcrunch-gradient-hover', 'linear-gradient(90deg, #182059 0%, #0078AC 50%, #3DAEDF 100%)');
    document.documentElement.style.setProperty('--map-marker-hover', `url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M6.00521 13.6673C3.83791 11.5 0.671875 9.01232 0.671875 5.66732C0.671875 2.7218 3.05969 0.333984 6.00521 0.333984C8.95073 0.333984 11.3385 2.7218 11.3385 5.66732C11.3385 9.01232 8.17251 11.5 6.00521 13.6673ZM6.00521 7.66732C7.10978 7.66732 8.00521 6.77189 8.00521 5.66732C8.00521 4.56275 7.10978 3.66732 6.00521 3.66732C4.90064 3.66732 4.00521 4.56275 4.00521 5.66732C4.00521 6.77189 4.90064 7.66732 6.00521 7.66732Z' fill='%230086C9'/%3E%3C/svg%3E%0A")`);
  }
};
