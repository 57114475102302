import '../../../../styling/filters.css'
import React, { useEffect, useState } from 'react';

interface CompanyTypeFilterProps {
    filter: any;
    addedFilters: any;
    apply: any;
}

const CompanyTypeFilter: React.FC<CompanyTypeFilterProps> = ({
    filter,
    addedFilters,
    apply
}) => {

    // #region CONSTANTS & STATE VARIABLES

    const [chosenOptions, setChosenOptions] = useState<string[]>([])




    const privateOptions = ['Private (All)', "Private Unlimited with Share Capital", "Private Unlimited without Share Capital"]
    const publicOptions = ['Public (All)', "Public Limited with Share Capital", "Old Public Limited"]

    // #endregion

    useEffect(() => {

        setChosenOptions([])

        addedFilters.forEach((element: { id: any; value: string[] }) => {
            if (element.id === filter.id) {
                setChosenOptions(element.value)
            }
        });


    }, [addedFilters.length]) // eslint-disable-line react-hooks/exhaustive-deps





    // #region SHOW COMPONENTS
    const showOptions = (options: any) => {

        let compArray: any = []

        options.forEach((element: string) => {

            compArray.push(<div className={`collapsibleFilter`}>

                <div className='cfTitle' onClick={() => addOption(element)}>
                    <span className='text-sm medium gray-700'>{element}</span>
                    <img className='cfArrow' src={'/assets/plus.png'} alt='plus' />
                </div>



            </div>)

        });

        return compArray

    }


    // #endregion


    // #region WEB REQUESTS

    // #endregion


    // #region BUTTONS CLICKED
    const addOption = (option: any) => {

        var cOptions = chosenOptions

        if (!cOptions.includes(option) && !(option.includes("Private") && cOptions.includes("Private (All)")) && !(option.includes("Public") && cOptions.includes("Public (All)"))) {
            if (option === "Private (All)") { cOptions = cOptions.filter(x => !x.includes("Private")) }
            if (option === "Public (All)") { cOptions = cOptions.filter(x => !x.includes("Public")) }
            cOptions.push(option)
            setChosenOptions(cOptions)
            apply(filter, cOptions)
        }

    }
    // #endregion


    // #region OTHER

    // #endregion

    return (
        <div className="multipleFilter">

            <span className='text-sm semibold gray-700 compTypeTitle'>PRIVATE</span>
            {showOptions(privateOptions)}
            <span className='text-sm semibold gray-700 compTypeTitle'>PUBLIC</span>
            {showOptions(publicOptions)}


        </div>
    )

}

export default CompanyTypeFilter