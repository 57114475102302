import './style.css';
import Spinner from './spinner';

export const Loader = () => {

  return (
    <div className="loader-container" data-cy="loader">
      <Spinner />
    </div>
  );
};

export default Loader;
