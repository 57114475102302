import "../styling/saved.css";
import "../styling/constants/divs.css";
import React, { useEffect, useState } from "react";

import PaymentMethodTile from "../components/Billing/PaymentMethodTile";
import CurrentPlanTile from "../components/Billing/CurrentPlanTile";
import UpdatePaymentPopUp from "../components/Billing/UpdatePaymentPopUp";
import EditPlanPopUp from "../components/Billing/EditPlanPopUp";
// import ChoosePlanPopUp from '../components/Billing/ChoosePlanPopUp';
import CancelPlanPopUp from "../components/Billing/CancelPlanPopUp";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Moment from "moment";
import ConfirmPlanPopUp from "../components/Billing/ConfirmPlanPopUp";
import BuyCreditsPopUpNew from "../components/Pricing/BuyCreditsPopUp";
import PricingPlanSelect from "../components/Pricing/PricingPlanSelect";
import PausePlanPopUp from "../components/Billing/PausePlanPopUp";
import UnpausePlanPopUp from "../components/Billing/UnpausePlanPopUp";
import { Mixpanel } from "../components/Mixpanel/Mixpanel";
import axiosWithToken from "axiosWithToken";
import Loader from "components/common/loader";

interface BillingProps {
  updateCredits: any,
  setUserP: any,
  saveToast: any,
  userP: any,
}

const Billing: React.FC<BillingProps> = ({ updateCredits, setUserP, saveToast, userP }) => {
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  // #region CONSTANTS & STATE VARIABLES

  const [updateVar, setUpdateVar] = useState(0);

  const [showUpdatePayment, setShowUpdatePayment] = useState(false);
  const [showEditPlan, setShowEditPlan] = useState(false);
  const [showCancelPlan, setShowCancelPlan] = useState(false);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [showConfirmPlan, setShowConfirmPlan] = useState(false);
  const [showBuyCredits, setShowBuyCredits] = useState(false);
  const [showPausePlan, setShowPausePlan] = useState(false);
  const [showUnpausePlan, setShowUnpausePlan] = useState(false);

  const [user, setUser] = useState<any>(null);

  const [plan, setPlan] = useState<any>(null);
  const [plans, setPlans] = useState<any>(null);

  const [customer, setCustomer] = useState<any>(null);
  const [invoices, setInvoices] = useState<any>(null);
  const [subscription, setSubscription] = useState<any>(null);
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const [newPlan, setNewPlan] = useState<any>(null);
  const [addingCredit, setAddingCredit] = useState<any>(null);

  const [selectedInvoices, setSelectedInvoices] = useState<any>([]);

  // #endregion

  // #region SHOW COMPONENTS

  const getTableRows = () => {
    var compArray = [];

    var index = 0;

    if (loading) {
      while (index < 10) {
        compArray.push(
          <tbody key={index}>
            <tr className="tableRow text-sm regular gray-600 pointer">
              <td className="width100">
                <div className="row ai-centre gap8">
                  <Skeleton height={20} width={20} />
                  <Skeleton width={200} />
                </div>
              </td>
              <td className="text-sm regular gray-600">
                <Skeleton width={90} />
              </td>

              <td>
                <Skeleton width={60} borderRadius={12} />
              </td>

              <td className="text-sm regular gray-600">
                <Skeleton width={90} />
              </td>

              <td className="text-sm regular gray-600">
                <Skeleton width={90} />
              </td>

              <td className="text-sm semibold primary-700">
                <Skeleton width={90} />
              </td>
            </tr>
          </tbody>
        );
        index++;
      }
    } else {
      if (invoices !== null) {
        invoices?.forEach((invoice: any) => {
          if (invoice.status !== "draft") {
            compArray.push(
              <tbody key={index}>
                <tr className="tableRow text-sm regular gray-600 pointer">
                  <td className="width100">
                    <div className="row ai-centre">
                      <input
                        id={`checkbox_id${index}`}
                        className="checkbox billing"
                        type="checkbox"
                        checked={selectedInvoices.includes(invoice.invoice_pdf)}
                        onChange={(e) =>
                          checkBoxClicked(invoice.invoice_pdf, e.target.checked)
                        }
                      />
                      <label
                        htmlFor={`checkbox_id${index}`}
                        className="checkboxLabel"
                      />
                      <span className="text-sm medium gray-900">{`Invoice #${invoice.number || "TBD"
                        } - ${Moment(
                          new Date(invoice.period_start * 1000)
                        ).format("MMM YYYY")}`}</span>
                    </div>
                  </td>
                  <td className="text-sm regular gray-600">
                    {`${Moment(new Date(invoice.period_start * 1000)).format(
                      "MMM DD, YYYY"
                    )}`}
                  </td>

                  <td className="flex justify-center items-center" style={{ height: "inherit !important" }}>
                    {invoice.paid && (
                      <div className="billingRowStatus h-[24px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M10 3L4.5 8.5L2 6"
                            stroke="#17B26A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-xs medium success-700">Paid</span>
                      </div>
                    )}
                  </td>

                  <td className="text-sm regular gray-600">
                    {`£${invoice.total / 100}`}
                  </td>

                  <td className="text-sm regular gray-600">Basic plan</td>

                  <td className="text-sm semibold primary-700">
                    <a href={invoice.invoice_pdf}>Download</a>
                  </td>
                </tr>
              </tbody>
            );
          }
          index++;
        });
      } else {
      }
    }

    return compArray;
  };

  const checkBoxClicked = (url: string, checked: boolean) => {
    if (url === "all") {
      let urlArray: any = [];
      if (checked) {
        invoices?.forEach((invoice: any) => {
          urlArray.push(invoice.invoice_pdf);
        });
      }
      setSelectedInvoices(urlArray);
    } else {
      let urlArray = selectedInvoices;
      if (checked) {
        urlArray.push(url);
        setSelectedInvoices(urlArray);
      } else {
        urlArray.splice(urlArray.indexOf(url), 1);
        setSelectedInvoices(urlArray);
      }
    }

    setUpdateVar(updateVar + 1);
  };

  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED
  //  const openCancelPlan = () => {
  //     setShowEditPlan(false)
  //     setShowCancelPlan(true)
  //  }
  const openPausePlan = () => {
    setShowEditPlan(false);
    setShowPausePlan(true);
  };
  const openChoosePlan = () => {
    Mixpanel.track("Sales Funnel", {
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      "User Type": "Buyer",
      "User ID": user.uid,
      Stage: "Select Plan",
      Date: new Date().toISOString(),
    });
    setShowEditPlan(false);
    setShowChoosePlan(true);
  };
  // #endregion

  // #region OTHER

  // #endregion

  useEffect(() => {
    if (userP) {
      let user = userP;
      let stripeCustomerID = user.stripeCustomerID;
      getCustomerData(stripeCustomerID);
      getPlanData(user.plan);
      setUser(user);
      Mixpanel.identify(user.uid);
      Mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        plan: user.plan,
      });
    }
  }, [userP]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showBuyCredits && !addingCredit) {
      setAddingCredit(true);
    }
  }, [showBuyCredits]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerData = async (cusID: any) => {
    setLoading(true);

    try {
      const response = await axiosWithToken.get(`${baseURL2}api/stripe/customer-data`);

      setCustomer(response.data.customer);
      setInvoices(response.data.invoices);
      setPaymentMethod(response.data.paymentMethod);
      localStorage.setItem("paymentMethod", JSON.stringify(response.data.paymentMethod));
      setSubscription(response.data.subscription || null);

      let buyCredits = localStorage.getItem("buyCredits");

      if (buyCredits === "true") {
        setShowBuyCredits(true);
        localStorage.setItem("buyCredits", "false");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching customer data:', error);
    }
  };

  const getPlanData = async (plan: any) => {
    setLoading2(true);

    try {
      const response = await axiosWithToken.get(`${baseURL2}api/stripe/plans/v2`);
      const plans = response.data;

      setPlans(plans);

      plans?.forEach((planVar: any) => {
        if (planVar.id === plan) {
          setPlan(planVar);
        }
      });

      let upgradePlan = localStorage.getItem("upgradePlan");

      if (upgradePlan === "true") {
        setShowChoosePlan(true);
        localStorage.setItem("upgradePlan", "false");
      }

      setLoading2(false);

    } catch (error) {
      setLoading2(false);
      console.error('Error fetching plan data:', error);
    }
  };


  const planChosen = (plan: any) => {
    setNewPlan(plan);
    setShowChoosePlan(false);
    if (paymentMethod === null) {
      setShowUpdatePayment(true);
      Mixpanel.track("Sales Funnel", {
        $name: user?.name,
        $email: user?.email,
        "Plan": user?.plan,
        "User Type": "Buyer",
        "User ID": user.uid,
        Stage: "Add Payment Method",
        Date: new Date().toISOString(),
      });
    } else {
      setShowChoosePlan(false);
      setShowConfirmPlan(true);
      Mixpanel.track("Sales Funnel", {
        $name: user?.name,
        $email: user?.email,
        "Plan": user?.plan,
        "User Type": "Buyer",
        "User ID": user.uid,
        Stage: "Confirm Plan",
        Date: new Date().toISOString(),
      });
    }
  };

  const downloadAll = () => {
    var index = 0;
    for (const url of selectedInvoices) {
      var link = document.createElement("a");
      link.href = url;
      link.download = `file${index}.pdf`;
      link.dispatchEvent(new MouseEvent("click"));
      // window.open(url);

      index++;
    }
  };

  const paymentMethodAdded = (pm: any) => {
    setPaymentMethod(pm);
    paymentCancel(pm);
  };

  const paymentCancel = (pm: any) => {
    setShowUpdatePayment(false);

    if (pm === null || pm === undefined) {
      setShowChoosePlan(true);
    } else if (newPlan !== null) {
      setShowConfirmPlan(true);
    } else if (addingCredit) {
      setShowBuyCredits(true);
    }
  };

  const closePayment = () => {
    setNewPlan(null);
    setAddingCredit(false);
    setShowUpdatePayment(false);
  };

  const confirmEditPayment = () => {
    setShowConfirmPlan(false);
    setShowBuyCredits(false);
    setShowUpdatePayment(true);
    Mixpanel.track("FeatureUsage", {
      "User ID": user.uid,
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      Stage: "Edit Payment Method",
      Date: new Date().toISOString(),
      "Featue:": "Edit Payment Method",
    });
  };

  const newPlanAdded = (sub: any) => {
    setShowConfirmPlan(false);

    saveToast("Plan Updated");

    if (user.plan === "free") {
      Mixpanel.track("Free to Paid Conversion", {
        $name: user?.name,
        $email: user?.email,
        "Plan": user?.plan,
        "User Type": "Buyer",
        "User ID": user.uid,
        Date: new Date().toISOString(),
      });
    }

    // if(!subscription)
    // {
    setPlan(newPlan);

    user.usedCredits = 0;
    user.credits = newPlan.Credits;
    user.plan = newPlan.id;
    localStorage.setItem("user", JSON.stringify(user));
    Mixpanel.track("Sales Funnel", {
      $name: user?.name,
      $email: user?.email,
      "User Type": "Buyer",
      "User ID": user.uid,
      Stage: "Plan Updated",
      Date: new Date().toISOString(),
    });

    setUser(user);
    setUserP(user);
    updateCredits(newPlan.Credits);
    Mixpanel.track("Plan Updated", {
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      "User Type": "Buyer",
      "User ID": user.uid,
      Date: new Date().toISOString(),
    });
    // }
    setNewPlan(null);
    setSubscription(sub);
  };

  const subscriptionCancelled = () => {
    setShowCancelPlan(false);

    subscription.cancel_at_period_end = true;
    setSubscription(subscription);
    Mixpanel.track("Subscription Cancelled", {
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      "User Type": "Buyer",
      "User ID": user.uid,
      Date: new Date().toISOString(),
    });
  };

  const closeBuyCredits = () => {
    setShowBuyCredits(false);
    setAddingCredit(false);
  };

  const resumePlan = () => {
    Mixpanel.track("Resume Subscription", {
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      "User Type": "Buyer",
      "User ID": user?.uid,
      "Date": new Date().toISOString()
    });
    setShowUnpausePlan(true);
  };

  const creditsPurchased = (amount: any) => {

    user.credits = +user.credits + amount;
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
    setUserP(user);

    updateCredits(+user.credits - user.usedCredits);
    closeBuyCredits();
  };
  return (
    <div className="fullScreen">
      <div className="container max-w-[80%]" >
        <div className="savedFiltersScreen">
          <div className="sfTitles">
            <span className="display-sm semibold gray-900">{"Billing"}</span>
            <span className="text-md regular gray-600">
              {"Manage your billing and payment details."}
            </span>
          </div>


          <div className="billingTilesRow">
            {!loading && !loading2 ? (
              <>
                <CurrentPlanTile
                  editPlan={() => {
                    setShowEditPlan(true);
                    Mixpanel.track("Sales Funnel", {
                      $name: user?.name,
                      $email: user?.email,
                      "Plan": user?.plan,
                      "User Type": "Buyer",
                      "User ID": user.uid,
                      Stage: "Edit Plan",
                      Date: new Date().toISOString(),
                    });
                  }}
                  plan={plan}
                  user={user}
                  addCredits={() => setShowBuyCredits(true)}
                  unpausePlan={() => resumePlan()}
                />
                <PaymentMethodTile
                  editPayment={() => setShowUpdatePayment(true)}
                  paymentMethod={paymentMethod}
                  customer={customer}
                />
              </>
            ) : <Loader />
            }
          </div>
          {invoices && invoices.length > 0 && <div className="billingInvoices">
            <div className="cptTopRow centre">
              <span className="text-lg semibold gray-900">Invoices</span>
              <div className="billingDownloadOptions">
                <button className="secondaryButton">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M6.6665 14.1667L9.99984 17.5M9.99984 17.5L13.3332 14.1667M9.99984 17.5V10M16.6665 13.9524C17.6844 13.1117 18.3332 11.8399 18.3332 10.4167C18.3332 7.88536 16.2811 5.83333 13.7498 5.83333C13.5677 5.83333 13.3974 5.73833 13.3049 5.58145C12.2182 3.73736 10.2119 2.5 7.9165 2.5C4.46472 2.5 1.6665 5.29822 1.6665 8.75C1.6665 10.4718 2.36271 12.0309 3.48896 13.1613"
                      stroke="#344054"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="text-sm semibold" onClick={downloadAll}>
                    Download selected
                  </span>
                </button>
              </div>
            </div>

            <table className="billingTable">
              <thead>
                <tr className="text-xs medium gray-600">
                  <th className="text-xs medium gray-600">
                    <div className="row ai-centre">
                      <input
                        id="checkbox_id"
                        className="checkbox billing"
                        type="checkbox"
                        checked={
                          invoices
                            ? selectedInvoices.length === invoices.length &&
                            invoices.length !== 0
                            : false
                        }
                        onChange={(e) =>
                          checkBoxClicked("all", e.target.checked)
                        }
                      />
                      <label htmlFor="checkbox_id" className="checkboxLabel" />
                      <span>Invoice</span>
                    </div>
                  </th>
                  <th className="text-xs medium gray-600">Billing date</th>
                  <th className="text-xs medium gray-600">Status</th>
                  <th className="text-xs medium gray-600">Amount</th>
                  <th className="text-xs medium gray-600">Plan</th>
                </tr>
              </thead>

              {getTableRows()}
            </table>
          </div>}
        </div>
      </div>

      {showUpdatePayment && (
        <UpdatePaymentPopUp
          close={closePayment}
          setCard={(x) => paymentMethodAdded(x)}
          cancel={paymentCancel}
        />
      )}
      {showEditPlan && (
        <EditPlanPopUp
          close={() => setShowEditPlan(false)}
          cancelPlan={openPausePlan}
          choosePlan={openChoosePlan}
          plan={plan}
          subscription={subscription}
        />
      )}
      {showChoosePlan && (
        <PricingPlanSelect
          close={() => setShowChoosePlan(false)}
          purchasePlan={(x: any) => planChosen(x)}
          plans={plans}
          planChoice={plan}
        />
      )}
      {showCancelPlan && (
        <CancelPlanPopUp
          close={() => setShowCancelPlan(false)}
          cancel={subscriptionCancelled}
          subscription={subscription}
        />
      )}
      {showPausePlan && (
        <PausePlanPopUp
          close={() => setShowPausePlan(false)}
          cancel={subscriptionCancelled}
          subscription={subscription}
        />
      )}
      {showConfirmPlan && (
        <ConfirmPlanPopUp
          close={() => setShowConfirmPlan(false)}
          plan={newPlan}
          dismissNewPlan={() => setNewPlan(null)}
          paymentMethod={paymentMethod}
          editPayment={confirmEditPayment}
          currentSubscription={subscription}
          complete={newPlanAdded}
        />
      )}

      {showBuyCredits && (
        <BuyCreditsPopUpNew
          user={user}
          paymentMethod={paymentMethod}
          editPayment={confirmEditPayment}
          close={closeBuyCredits}
          complete={creditsPurchased}
        />
      )}
      {showUnpausePlan && (
        <UnpausePlanPopUp
          close={() => setShowUnpausePlan(false)}
          cancel={() => setShowUnpausePlan(false)}
          subscription={subscription}
          pausedUntil={user.planPaused}
        />
      )}
    </div>
  );
}

export default Billing;
