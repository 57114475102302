import '../../styling/pricing.css'

import React, { useState } from 'react';
import Tooltip from '../constants/Tooltip';
import BuyCreditsPopUp from '../Billing/BuyCreditsPopUp';

interface Option {
    credits: number;
    price: number;
    rate: number;
    discount: number | null;
}

interface BuyCreditsPopUpNewProps {
    close: () => void;
    editPayment: () => void;
    paymentMethod: any;
    complete: any;
    user: any;
}

const BuyCreditsPopUpNew: React.FC<BuyCreditsPopUpNewProps> = ({ close, editPayment, paymentMethod, complete, user }) => {

    const [selected, setSelected] = useState<Option | null>(null);
    const primary600 = getComputedStyle(document.body).getPropertyValue('--primary-600');

    const showOptions = () => {
        let compArray: JSX.Element[] = [];
        const options: Option[] = [
            { credits: 100, price: 15, rate: 0.15, discount: null },
            { credits: 250, price: 35, discount: 5, rate: 0.14 },
            { credits: 500, price: 67, discount: 10, rate: 0.134 },
        ];

        options.forEach((element) => {
            compArray.push(
                <div className='row width100 ai-centre jc-between' key={element.credits}>
                    <div className='row gap12 ai-start'>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00065 11.5452C8.00065 13.3862 9.49304 14.8786 11.334 14.8786C13.1749 14.8786 14.6673 13.3862 14.6673 11.5452C14.6673 9.7043 13.1749 8.21191 11.334 8.21191C9.49304 8.21191 8.00065 9.7043 8.00065 11.5452ZM8.00065 11.5452C8.00065 10.7947 8.24871 10.1021 8.66732 9.54492V3.54525M8.00065 11.5452C8.00065 12.0955 8.13397 12.6146 8.37007 13.072C7.80843 13.5464 6.51121 13.8786 5.00065 13.8786C2.97561 13.8786 1.33398 13.2816 1.33398 12.5452V3.54525M8.66732 3.54525C8.66732 4.28163 7.0257 4.87858 5.00065 4.87858C2.97561 4.87858 1.33398 4.28163 1.33398 3.54525M8.66732 3.54525C8.66732 2.80887 7.0257 2.21191 5.00065 2.21191C2.97561 2.21191 1.33398 2.80887 1.33398 3.54525M1.33398 9.54525C1.33398 10.2816 2.97561 10.8786 5.00065 10.8786C6.45999 10.8786 7.7202 10.5686 8.31041 10.1198M8.66732 6.54525C8.66732 7.28163 7.0257 7.87858 5.00065 7.87858C2.97561 7.87858 1.33398 7.28163 1.33398 6.54525" stroke="#98A2B3" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div className='column ai-start gap4'>
                            <span className='gray-700 text-md'>{element.credits} credits  (£{element.price}.00)</span>
                            <div className='row ai-centre gap12'>
                                <span className='gray-700 medium text-sm'>£{element.rate} / credit</span>
                                {element.discount && <span className='primary-700 bold text-xs'>Save {element.discount}% </span>}
                            </div>
                        </div>
                    </div>
                    <button className='secondaryButton primary-700' onClick={() => setSelected(element)}>Buy</button>
                </div>
            );

            compArray.push(<div className='greyHorizontal' key={element.credits + "divider"} />);
        });

        compArray.pop();

        return compArray;
    };

    const openArticle = () => {
        let url = "https://bizcrunch.tawk.help/article/how-do-bizcrunch-credits-work";
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div>
            {<div className='tint'>
                <div className='column ai-centre gap32 z200 pricingTile padding24'>

                    <div className='row width100 jc-between ai-start'>

                        <div className='row ai-centre gap16'>
                            <div className='backGreyCircle'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className='column ai-start gap4'>
                                <span className='gray-900 text-lg semibold'>Top Up Credits</span>
                                <span className='text-sm regular gray-600'>Buy one-time credits without changing your monthly/yearly plan.</span>
                            </div>
                        </div>

                        <svg className='pointer' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={close}>
                            <path d="M18 6L6 18M6 6L18 18" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>


                    <div className='row'>
                        <div className='column ai-start width50 gap24 padding24'>

                            <div className='row ai-centre gap8 width100 jc-between'>
                                <span className='display-xs bold'>Top Up credits</span>
                                <div className='row ai-centre gap8 creditsBkg'>
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.00065 11.5452C8.00065 13.3862 9.49304 14.8786 11.334 14.8786C13.1749 14.8786 14.6673 13.3862 14.6673 11.5452C14.6673 9.7043 13.1749 8.21191 11.334 8.21191C9.49304 8.21191 8.00065 9.7043 8.00065 11.5452ZM8.00065 11.5452C8.00065 10.7947 8.24871 10.1021 8.66732 9.54492V3.54525M8.00065 11.5452C8.00065 12.0955 8.13397 12.6146 8.37007 13.072C7.80843 13.5464 6.51121 13.8786 5.00065 13.8786C2.97561 13.8786 1.33398 13.2816 1.33398 12.5452V3.54525M8.66732 3.54525C8.66732 4.28163 7.0257 4.87858 5.00065 4.87858C2.97561 4.87858 1.33398 4.28163 1.33398 3.54525M8.66732 3.54525C8.66732 2.80887 7.0257 2.21191 5.00065 2.21191C2.97561 2.21191 1.33398 2.80887 1.33398 3.54525M1.33398 9.54525C1.33398 10.2816 2.97561 10.8786 5.00065 10.8786C6.45999 10.8786 7.7202 10.5686 8.31041 10.1198M8.66732 6.54525C8.66732 7.28163 7.0257 7.87858 5.00065 7.87858C2.97561 7.87858 1.33398 7.28163 1.33398 6.54525" stroke="#98A2B3" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='text-sm semibold gray-600 '>{user.credits - user.usedCredits} credits left</span>
                                </div>
                            </div>
                            {<span className='text-sm regular'>Choose amount of credits to purchase</span>}


                            {showOptions()}

                        </div>

                        <div className='column ai-start width50 lightGrey padding50 gap32 br32'>
                            <div className='width100 column gap24'>
                                <div className='width100 row jc-between ai-centre'>
                                    <span className='gray-700 medium text-md'>Credit cost breakdown</span>
                                    <span className='primary-700 medium text-xs pointer' onClick={openArticle}>About credits</span>
                                </div>

                                <div className='width100 column gap16'>
                                    <div className='width100 row ai-centre jc-between'>
                                        <div className='row ai-centre gap12'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.66536 7.33333H11.8654C12.6121 7.33333 12.9855 7.33333 13.2707 7.47866C13.5216 7.60649 13.7255 7.81046 13.8534 8.06135C13.9987 8.34656 13.9987 8.71993 13.9987 9.46667V14M8.66536 14V4.13333C8.66536 3.3866 8.66536 3.01323 8.52004 2.72801C8.39221 2.47713 8.18824 2.27316 7.93735 2.14532C7.65214 2 7.27877 2 6.53203 2H4.13203C3.38529 2 3.01193 2 2.72671 2.14532C2.47583 2.27316 2.27185 2.47713 2.14402 2.72801C1.9987 3.01323 1.9987 3.3866 1.9987 4.13333V14M14.6654 14H1.33203M4.33203 4.66667H6.33203M4.33203 7.33333H6.33203M4.33203 10H6.33203" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                            <div className='row ai-centre gap8'>
                                                <span className='gray-700 regular text-sm'>Export Standard Company</span>
                                                <Tooltip icon={'normal'} text={"Standard company"} />
                                            </div>

                                        </div>
                                        <span className='gray-700 medium text-sm'>1 credits</span>
                                    </div>

                                    <div className='width100 row ai-centre jc-between'>
                                        <div className='row ai-centre gap12'>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.9987 11.4667V14M8.66536 4.13333C8.66536 3.3866 8.66536 3.01323 8.52004 2.72801C8.39221 2.47713 8.18824 2.27316 7.93735 2.14532C7.65214 2 7.27877 2 6.53203 2H4.13203C3.38529 2 3.01193 2 2.72671 2.14532C2.47583 2.27316 2.27185 2.47713 2.14402 2.72801C1.9987 3.01323 1.9987 3.3866 1.9987 4.13333V14M14.6654 14H1.33203M4.33203 4.66667H6.33203M4.33203 7.33333H6.33203" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.75 11V9.42105M8 10.2105H9.5M12.3 4L11.7797 5.42385C11.6951 5.6554 11.6528 5.77117 11.5871 5.86855C11.5288 5.95486 11.4571 6.03027 11.3751 6.09164C11.2826 6.16089 11.1726 6.20541 10.9527 6.29447L9.6 6.84211L10.9527 7.38974C11.1726 7.4788 11.2826 7.52332 11.3751 7.59257C11.4571 7.65394 11.5288 7.72935 11.5871 7.81566C11.6528 7.91304 11.6951 8.02881 11.7797 8.26036L12.3 9.68421L12.8203 8.26036C12.9049 8.02881 12.9472 7.91304 13.0129 7.81566C13.0712 7.72935 13.1429 7.65394 13.2249 7.59257C13.3174 7.52332 13.4274 7.4788 13.6473 7.38974L15 6.84211L13.6473 6.29447C13.4274 6.20541 13.3174 6.16089 13.2249 6.09164C13.1429 6.03027 13.0712 5.95486 13.0129 5.86855C12.9472 5.77117 12.9049 5.6554 12.8203 5.42385L12.3 4Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                            <div className='row ai-centre gap8'>
                                                <span className='gray-700 regular text-sm'>Export Premium Company</span>
                                                <Tooltip icon={'normal'} text={"Premium companies are the very best prospects in the UK. Click ‘About Credits’ above to learn more"} />
                                            </div>

                                        </div>
                                        <span className='gray-700 medium text-sm'>10 credits</span>
                                    </div>

                                    <div className='width100 row ai-centre jc-between'>
                                        <div className='row ai-centre gap12'>
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.67177 3H13.3584C14.0931 3 14.6943 3.675 14.6943 4.5V13.5C14.6943 14.325 14.0931 15 13.3584 15H2.67177C1.93706 15 1.33594 14.325 1.33594 13.5V4.5C1.33594 3.675 1.93706 3 2.67177 3Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.6943 4.5L8.0151 9.75L1.33594 4.5" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                            <div className='row ai-centre gap8'>
                                                <span className='gray-700 regular text-sm'>Export Owners Email address</span>
                                                <Tooltip icon={'normal'} text={"Get the direct email address of a company owner. Click ‘About Credits’ above to learn more"} />
                                            </div>

                                        </div>
                                        <span className='gray-700 medium text-sm'>3 credits</span>
                                    </div>


                                </div>

                            </div>


                            <div className='width100 pricingExample'>
                                <span className='gray-700 text-xs regular'><strong>Example: </strong>Exporting 50 companies, including 10 premium companies, each with an email address, will cost 290 credits.</span>
                            </div>

                            <div className='column ai-start gap20'>
                                <span className='gray-700 medium text-md'>All plans include</span>
                                <div className='column ai-start gap12'>
                                    <div className='row ai-centre gap12'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span className='gray-700 regular text-sm'>Profit & Revenue Estimations</span>
                                    </div>
                                    <div className='row ai-centre gap12'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span className='gray-700 regular text-sm'>Mail Merge Ready Exports</span>
                                    </div>
                                    <div className='row ai-centre gap12'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.3346 3.99805L6.0013 11.335L2.66797 8.00004" stroke="#1D2939" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span className='gray-700 regular text-sm'>Smart FIltering & Advanced Insights</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>}

            {selected && (
                <BuyCreditsPopUp
                    close={close}
                    editPayment={editPayment}
                    paymentMethod={paymentMethod}
                    complete={complete}
                    user={user}
                    option={selected}
                />
            )}
        </div>
    );
}

export default BuyCreditsPopUpNew;
