import '../../styling/saved.css';
import React, { useState } from 'react';
import PricingPlanSelect from './PricingPlanSelect';
import PricingAuthCardInput from './PricingAuthCardInput';


interface PricingComponentProps {
    close?: () => void;
}


interface PlanChoice {
    option: number;
    frequency: 'annually' | 'monthly';
}

const PricingComponent: React.FC<PricingComponentProps> = ({ close }) => {
    const [step, setStep] = useState<number>(1);
    const [planChoice, setPlanChoice] = useState<PlanChoice>({ option: 2, frequency: "annually" });

    const planSelected = (index: number, freq: 'annually' | 'monthly') => {
        setStep(2);
        setPlanChoice({ option: index, frequency: freq });
    };

    return (
        <div className='column ai-centre gap64 width100 marginTop200'>
            <span className='display-xl bold'>Pricing</span>
            {step === 1 && <PricingPlanSelect purchasePlan={planSelected} planChoice={planChoice} close={close} plans={undefined} />}
            {step === 2 && <PricingAuthCardInput backClicked={() => setStep(1)} />}
        </div>
    );
}

export default PricingComponent;
