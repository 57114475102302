import '../styling/login.css';
import '../styling/constants/button.css';
import '../styling/constants/inputs.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface AxiosResponseData {
  html: string;
}

const FundMyDeal: React.FC = () => {
  const [content, setContent] = useState<string | null>(null);
  const [done, setDone] = useState<boolean>(false);

  const baseURL2 = process.env.REACT_APP_LOCALURL2;

  useEffect(() => {
    if (baseURL2) {
      axios
        .get<AxiosResponseData>(`${baseURL2}caolanTest`)
        .then((response) => {
          setContent(response.data.html);
        })
        .catch((err) => {
          console.error('Error fetching content:', err);
        });
    }
  }, [baseURL2]);

  useEffect(() => {
    setDone(true);
    if (content && !done) {
      const scripts = document.querySelectorAll('script');
      scripts.forEach((script) => {
        try {
          const newScript = document.createElement('script');
          if (script.src) {
            newScript.src = script.src;
          } else {
            newScript.textContent = script.textContent;
          }

          document.body.appendChild(newScript);
        } catch (err) {
          console.error('Error injecting script: ', err);
        }
      });
    }
  }, [content, done]);

  return (
    <div className="column marginTop200 ai-centre height100">
      <div>
        {content ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default FundMyDeal;
