
import '../../../../styling/search.css'
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

export const SAFUpgradeBubble: React.FC = () => {

    // #region CONSTANTS & STATE VARIABLES
    const navigate = useNavigate()

    const [lowDebt, setLowDebt] = useState(false)
    const [profitable, setProfitable] = useState(false)
    const [retiringOwner, setRetiringOwner] = useState(false)
    const [simpleOwnership, setSimpleOwnership] = useState(false)



    // #endregion


    // #region SHOW COMPONENTS

    // #endregion


    // #region WEB REQUESTS

    // #endregion


    // #region BUTTONS CLICKED

    // #endregion


    // #region OTHER

    // #endregion

    const upgrade = () => {
        localStorage.setItem("upgradePlan", "true")
        navigate(`../billing`, { replace: true, state: { upgradePlan: true } });
    }


    return (
        <div className="searchBubble upgrade">

            <div className='fadeBottom' />

            <div className='row gap20 width150'>

                <div className={`smartAcquireFilter ${lowDebt ? "selected" : ""}`} onClick={() => setLowDebt(!lowDebt)}>

                    <div className='safLeft'>
                        <img className='safPlus' src={lowDebt ? 'assets/minus.png' : 'assets/plus.png'} alt='plus' />
                        <span className={`safTitle text-xs semibold ${lowDebt ? "selected" : ""}`}>Leverageable</span>
                    </div>
                    <img className='safIcon' src={lowDebt ? 'assets/lowDebtWhite.png' : 'assets/lowDebt.png'} alt='lowDebt' />

                </div>

                <div className={`smartAcquireFilter ${retiringOwner ? "selected" : ""}`} onClick={() => setRetiringOwner(!retiringOwner)}>

                    <div className='safLeft'>
                        <img className='safPlus' src={retiringOwner ? 'assets/minus.png' : 'assets/plus.png'} alt='plus' />
                        <span className={`safTitle text-xs semibold ${retiringOwner ? "selected" : ""}`}>Retiring Owner</span>
                    </div>
                    <img className='safIcon' src={retiringOwner ? 'assets/seniorfounderWhite.png' : 'assets/seniorfounder.png'} style={{ fill: '#0f0' }} alt='seniorFounder' />

                </div>

            </div>

            <div className='row gap20 width150'>

                <div className={`smartAcquireFilter ${profitable ? "selected" : ""}`} onClick={() => setProfitable(!profitable)}>

                    <div className='safLeft'>
                        <img className='safPlus' src={profitable ? 'assets/minus.png' : 'assets/plus.png'} alt='plus' />
                        <span className={`safTitle text-xs semibold ${profitable ? "selected" : ""}`}>Profitable</span>

                    </div>
                    <img className='safIcon' src={profitable ? 'assets/profitableWhite.png' : 'assets/profitable.png'} alt='profitable' />

                </div>

                <div className={`smartAcquireFilter ${simpleOwnership ? "selected" : ""}`} onClick={() => setSimpleOwnership(!simpleOwnership)}>

                    <div className='safLeft'>
                        <img className='safPlus' src={simpleOwnership ? 'assets/minus.png' : 'assets/plus.png'} alt='plus' />
                        <span className={`safTitle text-xs semibold ${simpleOwnership ? "selected" : ""}`}>Simple Ownership</span>

                    </div>
                    <img className='safIcon' src={simpleOwnership ? 'assets/simpleownershipWhite.png' : 'assets/simpleownerstructure.png'} style={{ fill: '#0f0' }} alt='simpleOwnership' />
                </div>
            </div>

            <div className='column ai-centre gap20'>
                <span className='text-sm regular gray-600'>In order to use this feature you will need to upgrade your account.</span>
                <button className='primaryButton' onClick={upgrade}>Upgrade</button>
            </div>

        </div>
    )

}



export function MoreUpgradeBubble() {


    // #region CONSTANTS & STATE VARIABLES
    const navigate = useNavigate()



    // #endregion


    // #region SHOW COMPONENTS

    // #endregion


    // #region WEB REQUESTS

    // #endregion


    // #region BUTTONS CLICKED

    // #endregion


    // #region OTHER

    // #endregion

    const upgrade = () => {
        localStorage.setItem("upgradePlan", "true")
        navigate(`../billing`, { replace: true, state: { upgradePlan: true } });
    }



    return (
        <div className="searchBubble advancedSearch upgraded">

            <div className='fadeBottom' />


            <div className='column gap20'>

                {/* <div className='searchBubbleTitleRow'>
                <div className='searchBubbleTitleSec'>
                    <span className='text-sm semibold gray-700'>Smart acquire filter</span>
                    <span className='text-sm regular gray-600'>Select company filters</span>
                </div>
            </div> */}

                <img className='width100' src='/assets/moreScreenshot.png' alt='more' />





                <div className='column ai-centre gap20'>
                    <span className='text-sm regular gray-600'>In order to use this feature you will need to upgrade your account.</span>
                    <button className='primaryButton' onClick={upgrade}>Upgrade</button>
                </div>








            </div>









        </div>
    )

}