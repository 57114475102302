import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import { lottieAnimationUrl } from "components/utils/network/endpoints";
import axiosWithToken from "axiosWithToken";
interface UnpausePlanPopUpProps {
  close: () => void;
  subscription: any
  pausedUntil: any;
  cancel: () => void;
}

const UnpausePlanPopUp: React.FC<UnpausePlanPopUpProps> = ({ close, subscription, pausedUntil }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const baseURL2 = process.env.REACT_APP_BASEURL2;
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      const response = await fetch(lottieAnimationUrl);
      const data = await response.json();
      setAnimationData(data);
    };

    fetchAnimationData();
  }, []);

  if (!animationData) {
    return null;
  }

  const unpausePlan = () => {
    setLoading(true);
    axiosWithToken
      .post(
        `${baseURL2}api/stripe/subscriptions/${subscription.id}/resume`)
      .then((response) => {
        setLoading(false);
        navigate(0);
        close();
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error pausing subscription:', error);
      });
  };

  return (
    <div className="tint">
      <div className="popUp unpausePlan text-left">
        <div className="row jc-end width100">
          <img
            className="closeButton pointer"
            src="/assets/x-close.png"
            onClick={close}
            alt="close"
          />
        </div>
        <div className="sfpuMain inner3">
          <span className="text-lg semibold gray-900">
            Are you sure you want to resume your subscription?
          </span>
          <span className="text-sm regular gray-600">
            Your subscription is currently paused until{" "}
            {new Date(pausedUntil).toDateString()}.
          </span>
        </div>
        {!loading && (
          <div className="row width100 jc-between gap12 column">
            <button
              className="primaryButton width100"
              onClick={unpausePlan}
              disabled={loading}
            >
              Resume my subscription
            </button>
            <div className="tooltip width100">
              <button
                className="secondaryButton width100"
                onClick={close}
                disabled={loading}
              >
                Keep my subscription paused
              </button>
            </div>
          </div>
        )}
        {loading && (
          <div className="loader">
            <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              style={{ transform: 'scale(0.7)' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UnpausePlanPopUp;
