import "../styling/saved.css";
import React, { useEffect, useState } from "react";
import SavedFilterRow from "../components/SavedFilterRow";
import LoadingPopUp from "../components/popUps/LoadingPopUp";
import SvgFolderIcon from "components/common/iconComponents/FolderIcon";
import { useNavigate } from "react-router-dom";
import SvgFilterIcon from "components/common/iconComponents/FilterIcon";
import axiosWithToken from "axiosWithToken";

interface SavedFiltersProps {
  isExports?: boolean;
  isFilters?: boolean;
  updateCredits?: any;
  creditsP?: number;
  hasData?: any;

}
const SavedFilters: React.FC<SavedFiltersProps> = ({ isExports, isFilters, updateCredits, creditsP, hasData }) => {
  // #region CONSTANTS & STATE VARIABLES
  //const baseURL = process.env.REACT_APP_BASEURL;
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const [savedFilters, setSavedFilters] = useState([]);
  const [updateVar, setUpdateVar] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false)

  // #endregion

  // #region SHOW COMPONENTS

  const showSaved = () => {
    let compArray: any = [];
    savedFilters?.forEach((element: any) => {
      compArray.push(
        <SavedFilterRow
          savedFilterP={element}
          deleteFilter={deleteFilter}
          isExports={isExports}
          updateCredits={updateCredits}
          creditsP={creditsP}
          key={element.id}
        />
      );
    });

    return compArray;
  };

  // #endregion

  // #region WEB REQUESTS

  const getAccountDetails = async () => {
    try {
      const response = await axiosWithToken.get(`${baseURL2}api/auth/account-dashboard-details`);
      const data = response.data;
      setSavedFilters(data.email);
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };


  const deleteFilter = (id: any) => {
    setSavedFilters(savedFilters.filter((x: any) => x.id !== id));
    setUpdateVar(updateVar + 1);
  };

  // #endregion

  // #region BUTTONS CLICKED

  // #endregion

  // #region OTHER

  // #endregion

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user") || 'null');

    if (user === null || user === undefined) {
      getAccountDetails();
    } else {
      if (isExports) {
        if (user.exports) {
          setSavedFilters(user.exports);

        } else {
          setSavedFilters([]);
        }
      }
      if (isFilters) {
        setSavedFilters(user.savedFilters || []);
      }
      setLoading(false);
    }
  }, [hasData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchNavigation = () => {
    navigate(`../`, { replace: true });
  };
  return (
    <div className="fullScreen">
      <div className="container max-w-[80%]">
        <div className="savedFiltersScreen">
          <div className="sfTitles">
            <span className="display-sm semibold gray-900">
              {isExports ? "Exports" : "Saved Filters"}
            </span>
            <span className="text-md regular gray-600">
              {isExports
                ? "Past exported companies"
                : "Track companies or perform new searches based on your saved filter criteria."}
            </span>
          </div>

          {savedFilters.length > 0 ? (
            showSaved()
          ) : (
            <div className="flex items-center justify-center flex-col gap-8 w-full min-h-[50vh]">
              {isExports ? <SvgFolderIcon /> : <SvgFilterIcon />}

              <div className="flex flex-col gap-2">
                <p className="text-lg font-semibold font-[InstrumentSans] text-center leading-[28px]">
                  {isExports ? "No exports yet" : "No saved filters yet"}
                </p>
                <p className="text-sm font-normal font-[Inter] text-center leading-[20px] decoration-none decoration-[from-font]">
                  {isExports ? "Export companies first" : "Save filters first"}
                </p>
              </div>
              <button
                onClick={handleSearchNavigation}
                className="rounded-[32px] text-lg font-normal text-gray-600 bg-transparent border border-gray-300 px-4 py-2 gap-2 flex items-center"
              >
                <p className="text-md font-semibold font-[Inter] text-center leading-[24px]">
                  Go to search
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
      {loading && <LoadingPopUp />}
    </div>
  );
}

export default SavedFilters;