import '../../styling/saved.css';
import React, { useEffect, useState } from 'react';


interface SuccessToastProps {
    text: string;
}

const SuccessToast: React.FC<SuccessToastProps> = ({ text }) => {
    const [hidden, setHidden] = useState<boolean>(false);

    useEffect(() => {
        setHidden(true);
    }, []);

    return (
        <div className={`successToast ${hidden ? "hidden" : ""}`}>
            <img className='successToastIcon' src='/assets/successToastIcon.png' alt='success' />
            <span className='text-sm semibold baseWhite'>{text}</span>
        </div>
    );
}

export default SuccessToast;
