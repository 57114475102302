import '../../../../styling/filters.css'
import '../../../../styling/constants/inputs.css'
import React, { useEffect, useState } from 'react';

interface RadioFilterProps {
  filter: any,
  addedFilters: any,
  apply: any,
  plan?: any
}
const RadioFilter: React.FC<RadioFilterProps> = ({ filter, addedFilters, apply, plan }) => {


  // #region CONSTANTS & STATE VARIABLES

  const [selectedValue, setSelectedValue] = useState<any>(null)

  // #endregion
  useEffect(() => {

    var filterExists = false

    addedFilters.forEach((element: any) => {
      if (element.id === filter.id) {
        filterExists = true
        setSelectedValue(element.value)
      }
    });


    if (!filterExists) { setSelectedValue(null) }

  }, [addedFilters.length]); // eslint-disable-line react-hooks/exhaustive-deps




  // #region SHOW COMPONENTS

  const showRadios = () => {

    let compArray: any = []
    filter?.values?.forEach((element: any) => {
      compArray.push(
        <div className="tooltip" key={element}>
          <div className='radioRow'>
            <input className='radio input-sm' type='radio' checked={selectedValue === element} onChange={(e) => applyFilter(element)} disabled={invalidPlan()} />
            <span className='text-sm medium gray-700'>{element}</span>
          </div>
          {invalidPlan() && <span className="tooltiptext radioTT button text-sm regular">You must upgrade to use this filter</span>}
        </div>
      )
    });

    return compArray

  }


  // #endregion


  // #region WEB REQUESTS

  // #endregion


  // #region BUTTONS CLICKED
  const applyFilter = (text: any) => {
    apply(filter, text)
  }
  // #endregion


  // #region OTHER

  const invalidPlan = () => {
    if ((filter.id === "4A2")
      && plan.includes("starter")) {
      return true
    }
    else {
      return false
    }
  }
  // #endregion

  return (
    <div className="radioFilter">
      {showRadios()}
    </div>
  )

}

export default RadioFilter;