import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
    element: React.ReactNode;
    loggedIn: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, loggedIn }) => {
    if (!loggedIn) {
        return <Navigate to="/login" />;
    }
    return <>{element}</>;
};

export default ProtectedRoute;