import '../../../styling/search.css'
import React, { } from 'react';

interface FilterOptionProps {
    filter: any,
    open: boolean,
    opened: any,
    selection: any,
    selectClick: any
}

const FilterOption: React.FC<FilterOptionProps> = ({ filter, open, opened, selection, selectClick }) => {


    // #region CONSTANTS & STATE VARIABLES
    const primary600 = getComputedStyle(document.body).getPropertyValue('--primary-600');
    // #endregion


    // #region SHOW COMPONENTS

    const showOptions = () => {

        let compArray: any = []

        filter.options.forEach((option: any) => {
            compArray.push(
                <span className={`text-sm medium pointer ${selection !== null && selection.id === option.id ? "primary-700" : "gray-700"}`}
                    onClick={() => selectClick(option)} key={option.title}>{option.title}</span>
            )
        });


        return compArray

    }

    const getIcon = () => {
        var icon

        if (filter.id === 0) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.93917 9.70858V12.2776L4.93904 9.70768L10.2928 9.70858H4.93917ZM10.2928 14.6474L2.66822 14.6473V9.90768H4.73917V12.4776H10.2928C11.0929 12.4776 11.7283 11.8053 11.7283 10.9927C11.7283 10.1947 11.1159 9.53226 10.3361 9.50832L10.3279 9.5084L10.2928 9.50858H5.13904V7.33879L6.37452 7.33803H10.2928L10.3279 7.33838L10.3361 7.33813C11.1159 7.31418 11.7283 6.65188 11.7283 5.8539C11.7283 5.04128 11.0929 4.36901 10.2928 4.36901H5.13904V2.19922H10.2928C12.222 2.19922 13.7992 3.82801 13.7992 5.8539C13.7992 6.79217 13.4603 7.64652 12.9042 8.29286L12.7919 8.42331L12.9042 8.55375C13.4603 9.20009 13.7992 10.0544 13.7992 10.9927C13.7992 13.0186 12.222 14.6474 10.2928 14.6474ZM4.73895 4.76791V6.9377H2.66799V4.76791H4.73895ZM2.27018 2.20076V4.37055H0.199219V2.20076H2.27018Z" fill={primary600} />
            </svg>
        }
        else if (filter.id === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clipPath="url(#clip0_1076_8290)">
                    <path d="M7.99998 4V2.5L9.49998 1L9.99998 2L11 2.5L9.49998 4H7.99998ZM7.99998 4L5.99999 5.99997M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1M8.5 6C8.5 7.38071 7.38071 8.5 6 8.5C4.61929 8.5 3.5 7.38071 3.5 6C3.5 4.61929 4.61929 3.5 6 3.5" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_1076_8290">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        }
        else if (filter.id === 2) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8.66536 7.33333H11.8654C12.6121 7.33333 12.9855 7.33333 13.2707 7.47866C13.5216 7.60649 13.7255 7.81046 13.8534 8.06135C13.9987 8.34656 13.9987 8.71993 13.9987 9.46667V14M8.66536 14V4.13333C8.66536 3.3866 8.66536 3.01323 8.52004 2.72801C8.39221 2.47713 8.18824 2.27316 7.93735 2.14532C7.65214 2 7.27877 2 6.53203 2H4.13203C3.38529 2 3.01193 2 2.72671 2.14532C2.47583 2.27316 2.27185 2.47713 2.14402 2.72801C1.9987 3.01323 1.9987 3.3866 1.9987 4.13333V14M14.6654 14H1.33203M4.33203 4.66667H6.33203M4.33203 7.33333H6.33203M4.33203 10H6.33203" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
        else if (filter.id === 3) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M7.9987 11.3333C7.9987 13.1743 9.49108 14.6667 11.332 14.6667C13.173 14.6667 14.6654 13.1743 14.6654 11.3333C14.6654 9.49238 13.173 8 11.332 8C9.49108 8 7.9987 9.49238 7.9987 11.3333ZM7.9987 11.3333C7.9987 10.5828 8.24676 9.89017 8.66536 9.33301V3.33333M7.9987 11.3333C7.9987 11.8836 8.13202 12.4027 8.36812 12.8601C7.80648 13.3345 6.50926 13.6667 4.9987 13.6667C2.97365 13.6667 1.33203 13.0697 1.33203 12.3333V3.33333M8.66536 3.33333C8.66536 4.06971 7.02374 4.66667 4.9987 4.66667C2.97365 4.66667 1.33203 4.06971 1.33203 3.33333M8.66536 3.33333C8.66536 2.59695 7.02374 2 4.9987 2C2.97365 2 1.33203 2.59695 1.33203 3.33333M1.33203 9.33333C1.33203 10.0697 2.97365 10.6667 4.9987 10.6667C6.45803 10.6667 7.71825 10.3567 8.30846 9.90785M8.66536 6.33333C8.66536 7.06971 7.02374 7.66667 4.9987 7.66667C2.97365 7.66667 1.33203 7.06971 1.33203 6.33333" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
        else if (filter.id === 4) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M14.6654 14V12.6667C14.6654 11.4241 13.8155 10.38 12.6654 10.084M10.332 2.19384C11.3093 2.58943 11.9987 3.54754 11.9987 4.66667C11.9987 5.78579 11.3093 6.7439 10.332 7.13949M11.332 14C11.332 12.7575 11.332 12.1362 11.129 11.6462C10.8584 10.9928 10.3393 10.4736 9.68585 10.203C9.1958 10 8.57454 10 7.33203 10H5.33203C4.08952 10 3.46826 10 2.97821 10.203C2.3248 10.4736 1.80567 10.9928 1.53502 11.6462C1.33203 12.1362 1.33203 12.7575 1.33203 14M8.9987 4.66667C8.9987 6.13943 7.80479 7.33333 6.33203 7.33333C4.85927 7.33333 3.66536 6.13943 3.66536 4.66667C3.66536 3.19391 4.85927 2 6.33203 2C7.80479 2 8.9987 3.19391 8.9987 4.66667Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
        else if (filter.id === 5) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M9.33464 1.51367V4.26737C9.33464 4.64073 9.33464 4.82742 9.4073 4.97003C9.47121 5.09547 9.5732 5.19745 9.69864 5.26137C9.84125 5.33403 10.0279 5.33403 10.4013 5.33403H13.155M10.668 12.334L9.66797 11.334M9.33464 1.33398H5.86797C4.74786 1.33398 4.18781 1.33398 3.75999 1.55197C3.38366 1.74372 3.0777 2.04968 2.88596 2.426C2.66797 2.85383 2.66797 3.41388 2.66797 4.53398V11.4673C2.66797 12.5874 2.66797 13.1475 2.88596 13.5753C3.0777 13.9516 3.38366 14.2576 3.75999 14.4493C4.18781 14.6673 4.74786 14.6673 5.86797 14.6673H10.1346C11.2547 14.6673 11.8148 14.6673 12.2426 14.4493C12.6189 14.2576 12.9249 13.9516 13.1166 13.5753C13.3346 13.1475 13.3346 12.5874 13.3346 11.4673V5.33398L9.33464 1.33398ZM10.3346 9.66732C10.3346 10.956 9.28997 12.0007 8.0013 12.0007C6.71264 12.0007 5.66797 10.956 5.66797 9.66732C5.66797 8.37865 6.71264 7.33398 8.0013 7.33398C9.28997 7.33398 10.3346 8.37865 10.3346 9.66732Z" stroke={primary600} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }

        return icon
    }

    // #endregion


    // #region WEB REQUESTS

    // #endregion


    // #region BUTTONS CLICKED
    const titleClicked = () => {
        opened(filter.id, !open)
        if (filter.options.length === 1) { selectClick(filter.options[0]) }
    }
    // #endregion


    // #region OTHER

    // #endregion

    return (
        <div className={`filterOption ${open ? "open" : ""}`}>

            <div className='foTitle' onClick={titleClicked}>
                {getIcon()}
                <span className={`text-sm gray-700 ${open ? "semibold" : "medium"}`}>{filter.title}</span>
            </div>

            {(open && filter.options.length > 1) && <div className='foOptions'>
                {showOptions()}
            </div>}

        </div>
    )

}

export default FilterOption;