import React, { useEffect, useState } from 'react';
import '../../styling/saved.css';
import Lottie from 'lottie-react';
import { lottieAnimationUrl } from 'components/utils/network/endpoints';

const LoadingPopUp: React.FC = () => {
    const [animationData, setAnimationData] = useState<any>(null);

    useEffect(() => {
        const fetchAnimationData = async () => {
            const response = await fetch(lottieAnimationUrl);
            const data = await response.json();
            setAnimationData(data);
        };

        fetchAnimationData();
    }, []);

    if (!animationData) {
        return null;
    }

    return (
        <div className='tint'>
            <div className='popUp loading'>
                <Lottie
                    animationData={animationData}
                    loop={true}
                    autoplay={true}
                    style={{ transform: 'scale(0.7)' }}
                />
            </div>
        </div>
    );
};

export default LoadingPopUp;
