import { useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import '../styling/constants/divs.css';
interface PaymentComponentProps {
  stripeResponse: any;
  submitCount: number;
  payment: boolean;
  savedCard?: any | null;
  intentID?: string;
}
const PaymentComponent: React.FC<PaymentComponentProps> = ({ stripeResponse, submitCount, payment, savedCard, intentID }) => {
  // #region CONSTANTS & STATE VARIABLES
  const stripe = useStripe();
  const elements = useElements();
  const [addressCreated, setAddressCreated] = useState(false);
  const baseURL2 = process.env.REACT_APP_BASEURL2;
  // #endregion

  // #region BUTTONS CLICKED

  const handleSubmit = async () => {


    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }


    var stripeResVar;

    if (payment) {

      if (savedCard && intentID) {

        stripeResVar = await stripe.confirmCardPayment(intentID, {
          payment_method: savedCard,

        });
      }
      else {
        stripeResVar = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${baseURL2}pay`,
          },
          redirect: "if_required"
        });
      }
    }
    else {
      stripeResVar = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${baseURL2}pay`,
        },
        redirect: "if_required"
      });
    }

    if (stripeResVar.hasOwnProperty("error") && stripeResVar.error) {
      stripeResponse({ success: false, error: stripeResVar.error.message });

    }
    else {
      stripeResponse({ success: true, response: stripeResVar })
    }

  };

  // #endregion


  useEffect(() => {
    if (elements !== null && !addressCreated) {
      const paymentElement = elements.create('payment');
      if (!savedCard) { paymentElement.mount('#payment'); }
      setAddressCreated(true)

    }
  }, [elements]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    if (submitCount > 0) {
      handleSubmit();
    }
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='servicePaymentRow width100'>
      <div className='servicePaymentColumn '>
        <div id='payment' />
        <div id='billingAddress' />
      </div>
    </div>
  );
};

export default PaymentComponent;