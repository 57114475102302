import { auth } from '..';
import '../styling/login.css';
import '../styling/constants/button.css';
import '../styling/constants/inputs.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail, onAuthStateChanged } from '@firebase/auth';
import { Mixpanel } from '../components/Mixpanel/Mixpanel';

const ForgotPassword: React.FC = () => {
  const [emailText, setEmailText] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });
    return () => unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submitForgotPassword = () => {
    Mixpanel.track('FeatureUsage', {
      'User ID': auth?.currentUser?.uid,
      'Date': new Date().toISOString(),
      'Feature': 'Reset password',
    });

    setLoading(true);
    sendPasswordResetEmail(auth, emailText)
      .then(() => {
        setEmailSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;

        if (errorCode === 'auth/invalid-email') {
          setEmailError('Invalid email or username structure');
        }
        if (errorCode === 'auth/user-not-found') {
          setEmailError('No account exists with this email or username');
        }
        if (errorCode === 'auth/internal-error') {
          setEmailError('An internal error occurred');
        }

        setLoading(false);
      });
  };

  const submitClicked = () => {
    setEmailError('');
    setEmailSuccess(false);

    if (emailText === '') {
      setEmailError('Please enter an email');
    } else if (!validateEmail(emailText)) {
      setEmailError('Invalid email structure');
    } else {
      submitForgotPassword();
    }
  };

  const validateEmail = (email: string): boolean => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null;
  };

  const returnToLogin = () => {
    navigate(`../login`, { replace: true });
  };

  return (
    <div className="loginScreen">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8041 12H29.4298C33.4926 12 36.7861 15.4252 36.7861 19.6505C36.7861 21.6095 36.078 23.3966 34.9136 24.75C36.078 26.1034 36.7861 27.8905 36.7861 29.8495C36.7861 34.0748 33.4926 37.5 29.4298 37.5L13.9003 37.4997V27.2991H18.8041V22.2006H21.2562V22.1991H29.4298C29.4544 22.1991 29.479 22.1992 29.5035 22.1995C30.8237 22.1589 31.8819 21.0332 31.8819 19.6505C31.8819 18.242 30.7841 17.1003 29.4298 17.1003H18.8041V12ZM18.8044 32.3997V27.3009H29.4298C29.4544 27.3009 29.479 27.3008 29.5035 27.3005C30.8237 27.3411 31.8819 28.4668 31.8819 29.8495C31.8819 31.258 30.7841 32.3997 29.4298 32.3997H18.8044Z"
          fill="black"
        />
        <path d="M13.8998 17.1034H9V12.0031H13.9042V17.0981H18.804V22.1984H13.8998V17.1034Z" fill="black" />
      </svg>

      <div className="loginTitles">
        <span className="display-xs semibold gray-900">Reset Password</span>
      </div>

      <div className="loginInputs">
        <div className="loginInput">
          <span className="text-sm medium gray-700">Email address</span>
          <input
            className={`textInput ${emailError !== '' ? 'error' : ''}`}
            type="email"
            autoCorrect="off"
            autoCapitalize="none"
            placeholder="e.g. john@example.com"
            value={emailText}
            onChange={(e) => setEmailText(e.target.value)}
          />
          {emailError !== '' && <span className="text-sm regular error-500">{emailError}</span>}
        </div>

        <button
          className="loginBtn primaryButton"
          disabled={emailText === '' || !validateEmail(emailText)}
          onClick={submitClicked}
        >
          {'Submit'}
        </button>
        {emailSuccess && <span className="text-sm medium success-700">{"You have been sent an email to reset your password"}</span>}
        {loading && <span className="text-sm medium gray-700">{"Checking email..."}</span>}

        {emailSuccess && !loggedIn && (
          <button className="loginBtn secondaryButton" onClick={returnToLogin}>
            {'Return to Login'}
          </button>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
