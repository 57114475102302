import { mixpanelApiHost } from "components/utils/network/endpoints";
import mixpanel from "mixpanel-browser";

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
    debug: process.env.NODE_ENV === "development",
    track_pageview: true,
    persistence: "localStorage",
    api_host: mixpanelApiHost,
  });
}

interface MixpanelActions {
  identify: (id: string) => void;
  alias: (id: string) => void;
  track: (name: string, props?: Record<string, any>) => void;
  people: {
    set: (props: Record<string, any>) => void;
  };
  reset: () => void;
}

const actions: MixpanelActions = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props?: Record<string, any>) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: Record<string, any>) => {
      mixpanel.people.set(props);
    },
  },
  reset: () => {
    mixpanel.reset();
  },
};

export const Mixpanel: MixpanelActions = actions;
