import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { getIdToken, onAuthStateChanged } from "firebase/auth";
import { auth } from ".";
import { Mixpanel } from "./components/Mixpanel/Mixpanel";
import Header from "./components/Header";
import Payments from "./screens/Payments";
import Login from "./screens/Login";
import Account from "./screens/Account";
import Search from "./screens/Search";
import axios from "axios";
import SavedFilters from "./screens/SavedFilters";
import Billing from "./screens/Billing";
import SharedExport from "./screens/SharedExport";
import SuccessToast from "./components/popUps/SuccessToast";
import Admin from "./screens/Admin";
import ForgotPassword from "./screens/ForgotPassword";
import VerifyEmail from "./screens/VerifyEmail";
import PricingComponent from "./components/Pricing/PricingComponent";
import PricingIFrame from "./components/Pricing/PricingIFrame";
import { themeSetter } from "./localFunctions/themeSetter";
import { ParentContext } from "./components/constants/ParentContext";
import VerifyEmailSuccess from "./screens/VerifyEmailSuccess";
import PoweredByBC from "./components/PoweredByBC";
import FundMyDeal from "./screens/FundMyDeal";
import AIDataScreen from "./screens/AIData";
import ProtectedRoute from "ProtectedRoute";
import axiosWithToken from "axiosWithToken";
import Loader from "components/common/loader";

function App() {
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [credits, setCredits] = useState<any>(null);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>();
  const [succesText, setSuccessText] = useState("");
  const [choosePlan, setChoosePlan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext<any>(ParentContext);
  const [isBiz, setIsBiz] = useState(
    context.isBiz4Biz || context.isHarbour ? false : true
  );

  const [isHarbourClub, setIsHarbourClub] = useState(
    context.isHarbour ? true : false
  );

  const [isBiz4Biz, setIsBiz4Biz] = useState(context.isBiz4Biz ? true : false);

  let params = new URL(document.location.href).searchParams;
  let utm_source = params.get("utm_source")
  if (utm_source !== null) {
    Mixpanel.track("RepeatLogin", {
      "User ID": auth.currentUser ? auth.currentUser.uid : null,
      Date: new Date().toISOString(),
      $email: auth?.currentUser?.email,
      $name: auth?.currentUser?.displayName,
      //"Subscription": userData?.plan,
      "User Type": "Buyer",
    });
  }

  themeSetter(isBiz); // run themeSetter function to set primary colors

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        const idToken = await getIdToken(user!);
        localStorage.setItem('idToken', idToken);

        setLoggedIn(true);
        let cr = localStorage.getItem("currentlyRegistering");
        if (cr !== null) {
          localStorage.removeItem("currentlyRegistering");
          //setUserData(null);
        } else {
          loadReducedUserFromDB(user.uid);
        }

        checkSession(user.uid);

        let currentTime = new Date().getTime()
        if (auth.currentUser && auth.currentUser.metadata.creationTime) {
          let creationTime = new Date(auth.currentUser.metadata.creationTime).getTime();
          if ((currentTime - creationTime) > 300000) { //It's been over 5 minutes since account was created
            let userData = JSON.parse(localStorage.getItem("user")!)

            Mixpanel.track("RepeatLogin", {
              $name: userData?.name,
              $email: userData?.email,
              "Plan": userData?.plan,
              "User Type": "Buyer",
              "User ID": userData?.uid,
              "Date": new Date().toISOString(),
            })
          }
        }
      } else {
        setLoggedIn(false);
        setUserData(null);
        if (!isBiz && window.location.pathname !== "/login") {
          window.location.pathname = "/login";
        }
      }
    });
    return () => {
      Mixpanel.reset();
      unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getTokenOnAppRefresh = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const idToken = await getIdToken(user, true);

          localStorage.setItem('idToken', idToken);
        } catch (error) {
          console.error('Error getting token:', error);
        }
      } else {
        console.log('User is not authenticated');
      }
    };
    getTokenOnAppRefresh();
  }, []);

  useEffect(() => {
    const refreshToken = async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          const idToken = await getIdToken(user, true);

          localStorage.setItem('idToken', idToken);
          console.log('Token refreshed');
        } catch (error) {
          console.error('Error getting token:', error);
        }
      } else {
        console.log('User is not authenticated');
      }
    };

    const tokenRefreshTimeout = setInterval(() => {
      refreshToken();
    }, 55 * 60 * 1000);

    return () => {
      clearInterval(tokenRefreshTimeout);
    };
  }, []);

  const checkSession = (uid: any) => {
    axios
      .get(baseURL2 + "getUserSession", {
        params: {
          uid: uid,
        },
      })
      .then((response) => {
        let lastSession = response.data.lastSession;
        if (lastSession) {
          let time = new Date().getTime();

          if (time - lastSession > 30 * 86400000) {
            setSessionCIO(uid);
          }
        }
        setSession(uid);
      });
  };

  const setSession = (uid: any) => {
    axios
      .get(baseURL2 + "setUserSession", {
        params: {
          uid: uid,
        },
      })
      .then((response) => { });
  };

  const setSessionCIO = (uid: any) => {
    axios
      .get(baseURL2 + "userSessionCIO", {
        params: {
          uid: uid,
        },
      })
      .then((response) => { });
  };

  const loadReducedUserFromDB = async (uid: any) => {
    const idToken = localStorage.getItem('idToken');
    if (idToken) {
      try {
        setIsLoading(true);
        const response = await axiosWithToken.get(`${baseURL2}api/auth/user`);
        const data = response.data;

        if (!data.error) {
          Mixpanel.identify(data.uid);
          Mixpanel.people.set({
            $name: data.name,
            $email: data.email,
            plan: data.plan,
          });

          setUserData(data);
          localStorage.setItem("user", JSON.stringify(data));

          let credits = data.credits;
          let creditsUsed = data.usedCredits;
          setCredits(credits - creditsUsed);

          let stripeCustomerID = data.stripeCustomerID;
          if (stripeCustomerID) {
            getCustomerData(stripeCustomerID);
          }
          await loadUserFromDB();
        }
      } catch (error) {
        console.error("Error loading reduced user:", error);
        setIsLoading(false);
      }
    }
  };

  const loadUserFromDB = async () => {
    const idToken = localStorage.getItem('idToken');
    if (idToken) {
      try {
        setIsLoading(true);
        const response = await axiosWithToken.get(`${baseURL2}api/auth/account-dashboard-details`);
        const data = response.data;

        if (!data.error) {
          setUserData(data);
          setIsBiz(!data.isHarbourClub && !data.isBiz4Biz);
          if (!isHarbourClub) {
            setIsHarbourClub(data.isHarbourClub);
          }
          if (!isBiz4Biz) {
            setIsBiz4Biz(data.isBiz4Biz);
          }

          localStorage.setItem("user", JSON.stringify(data));

          let credits: any = data.credits;
          let creditsUsed: any = data.usedCredits;
          setCredits(credits - creditsUsed);

          let stripeCustomerID = data.stripeCustomerID;
          if (stripeCustomerID) {
            getCustomerData(stripeCustomerID);
          }
        } else {
          console.error("Error loading account dashboard details:", data.error);
        }
      } catch (error) {
        console.error("Error loading account dashboard details:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getCustomerData = async (cusID: any) => {
    if (auth.currentUser?.emailVerified) {
      setIsLoading(true);
      try {
        const response = await axiosWithToken.get(`${baseURL2}api/stripe/customer-data`);

        localStorage.setItem("paymentMethod", JSON.stringify(response.data.paymentMethod));
        setIsLoading(false);

      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching customer data:", error);
      }
    }
  };

  const successClicked = async (text: string) => {
    setSuccessText(text);
    setShowSuccessToast(true);
    await delay(5000);
    setShowSuccessToast(false);
  };

  const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));

  const showChoosePlan = (show: any) => {
    setChoosePlan(show);
  };
  return (
    <div className="App">
      {isLoading && <Loader />}
      <ParentContext.Provider value={{ isHarbour: isHarbourClub, isBiz4Biz }}>
        <Router>
          {window.location.pathname !== "/iframe" && (
            <Header
              updateCredits={(x: any) => setCredits(x)}
              loggedIn={loggedIn}
              user={userData}
              creditsP={credits}
              saveToast={successClicked}
              showChoosePlanP={choosePlan}
            />
          )}

          <Routes>
            <Route
              path="/"
              element={
                <Search
                  user={userData}
                  creditsP={credits}
                  updateCredits={(x: any) => setCredits(x)}
                  saveToast={successClicked}
                />
              }
            />

            <Route
              path="/login"
              element={
                loggedIn ? (
                  <Navigate to="/search" />
                ) : (
                  <Login
                    setUser={(x: any) => setUserData(x)}
                    setCredits={(x: any) => setCredits(x)}
                  />
                )
              }
            />
            <Route
              path="/billing"
              element={
                <ProtectedRoute loggedIn={loggedIn} element={<Billing updateCredits={(x: any) => setCredits(x)} setUserP={(x: any) => setUserData(x)} saveToast={successClicked} userP={userData} />} />
              }
            />
            <Route path="/payments" element={<Payments />} />

            <Route
              path="/account"
              element={
                <ProtectedRoute loggedIn={loggedIn} element={<Account />} />
              }
            />
            <Route
              path="/search"
              element={
                <Search
                  user={userData}
                  creditsP={credits}
                  updateCredits={(x: any) => setCredits(x)}
                  saveToast={successClicked}
                />
              }
            />
            <Route
              path="/search/newUser"
              element={
                <Search
                  user={userData}
                  creditsP={credits}
                  updateCredits={(x: any) => setCredits(x)}
                  saveToast={successClicked}
                />
              }
            />
            <Route path="/aidata" element={<AIDataScreen />} />
            <Route
              path="/savedFilters"
              element={
                <ProtectedRoute
                  loggedIn={loggedIn}
                  element={<SavedFilters isFilters={true} hasData={userData && userData.savedFilters} />}
                />
              }
            />
            <Route
              path="/exports"
              element={
                <ProtectedRoute
                  loggedIn={loggedIn}
                  element={
                    <SavedFilters
                      isExports={true}
                      updateCredits={(x: any) => setCredits(x)}
                      creditsP={credits}
                      hasData={userData && userData.exports}
                    />
                  }
                />
              }
            />
            <Route path="/sharedExport/:user/:exportID" element={<SharedExport />} />
            <Route
              path="/admin"
              element={
                <ProtectedRoute loggedIn={loggedIn} element={<Admin />} />
              }
            />
            <Route path="/pricing" element={<PricingComponent />} />

            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route
              path="/verify-email-success"
              element={<VerifyEmailSuccess showChoosePlan={showChoosePlan} />}
            />
            <Route path="/passwordReset" element={<ForgotPassword />} />
            <Route
              path="/iframe"
              element={<PricingIFrame userP={userData} />}
            />
            <Route path="/fundMyDeal" element={<FundMyDeal />} />
          </Routes>
        </Router>

        {showSuccessToast && <SuccessToast text={succesText} />}

        {!isBiz && <PoweredByBC />}

        {window.location.pathname !== "/iframe" && (
          <img
            className="bottomGraphic"
            src={
              isBiz
                ? "/assets/bottomGraphic.png"
                : "/assets/bottomGraphicHC.png" // TODO: different graphic for biz4biz?
            }
            alt="bottomGraphic"
          />
        )}
      </ParentContext.Provider>
    </div>
  );
}

export default App;
