
import '../../../../styling/search.css'
import '../../../../styling/constants/typography.css'
import React, { useState } from 'react';

import RevenueFilter from '../../Advanced/FilterTypes/RevenueFilter';

interface RevenueSearchProps {
    appliedFilters: any,
    applyFilter: any,
    approx?: any,
    setApprox?: any,
    remove: any,
    done: any
}

const RevenueSearch: React.FC<RevenueSearchProps> = ({ appliedFilters, applyFilter, approx, setApprox, remove, done }) => {

    const [clearCount, setClearCount] = useState(0)


    // #region SHOW COMPONENTS

    // #endregion


    // #region WEB REQUESTS

    // #endregion


    // #region BUTTONS CLICKED

    // #endregion


    // #region OTHER

    // #endregion


    const clear = () => {
        remove(filter)
        setClearCount(clearCount + 1)
    }

    let filter = { id: "3C3", title: "Turnover", type: "range", subType: "financial", isRevenue: true }


    return (
        <div className="searchBubble revenue">
            <div className='searchBubbleTitleRow'>
                <div className='searchBubbleTitleSec'>
                    <span className='text-sm semibold gray-700'>Revenue</span>
                    <span className='text-sm regular gray-600'>Select revenue range</span>
                </div>
                <span className='text-sm medium primary-700 pointer' onClick={clear}>Clear</span>
            </div>

            <RevenueFilter filter={filter} addedFilters={appliedFilters} apply={applyFilter} approx={approx} setApprox={setApprox} clearCount={clearCount} hideApply={true} />

            <div className='loginForgotRow margin24'><button onClick={done} className='text-sm medium gray-700'>Done</button></div>
        </div>
    )

}

export default RevenueSearch;