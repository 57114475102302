import '../../styling/saved.css';
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { lottieAnimationUrl } from 'components/utils/network/endpoints';
import axiosWithToken from 'axiosWithToken';
import { useNavigate } from 'react-router-dom';

interface CancelPlanPopUpProps {
    close: () => void;
    cancel: () => void;
    subscription: any;
}

const CancelPlanPopUp: React.FC<CancelPlanPopUpProps> = ({ close, cancel, subscription }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [animationData, setAnimationData] = useState<any>(null);
    const baseURL2 = process.env.REACT_APP_BASEURL2;

    useEffect(() => {
        const fetchAnimationData = async () => {
            const response = await fetch(lottieAnimationUrl);
            const data = await response.json();
            setAnimationData(data);
        };

        fetchAnimationData();
    }, []);

    if (!animationData) {
        return null;
    }

    const cancelSubscription = async () => {
        setLoading(true);
        try {
            await axiosWithToken.post(`${baseURL2}api/stripe/subscriptions/${subscription.id}/cancel`);

            setLoading(false);
            cancel();
            navigate(0);
        } catch (error) {
            setLoading(false);
            console.error('Error cancelling subscription:', error);
        }
    };

    return (
        <div className="tint">
            <div className="popUp cancelPlan">
                <div className="row jc-end width100">
                    <img
                        className="closeButton pointer"
                        src="/assets/x-close.png"
                        onClick={close}
                        alt="close"
                    />
                </div>

                <div className="sfpuMain inner3">
                    <span className="text-lg semibold gray-900">Cancel subscription</span>
                    <span className="text-sm regular gray-600">
                        Are you sure you want to cancel your subscription?
                    </span>
                </div>

                {loading && (
                    <div className="loader">
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            autoplay={true}
                            style={{ transform: 'scale(0.7)' }}

                        />
                    </div>
                )}

                {!loading && (
                    <div className="row width100 jc-between gap12">
                        <button className="secondaryButton width40" onClick={close} disabled={loading}>
                            Keep plan
                        </button>
                        <div className="tooltip">
                            <button
                                className="primaryButton width100"
                                onClick={cancelSubscription}
                                disabled={loading}
                            >
                                Cancel Subscription
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CancelPlanPopUp;
