import { auth } from "..";
import "../styling/login.css";
import "../styling/constants/button.css";
import "../styling/constants/inputs.css";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  signInWithEmailAndPassword,
} from "@firebase/auth";
import { ParentContext } from "../components/constants/ParentContext";
import ReactPlayer from "react-player";
import { Mixpanel } from "../components/Mixpanel/Mixpanel";
import { bizCrunchPolicyUrl, vimeoBiz4BizUrl, vimeoUrl, youtubeUrl } from "components/utils/network/endpoints";
import axiosWithToken from "axiosWithToken";
import Loader from "components/common/loader";

interface LoginProps {
  setUser: any;
  setCredits?: any;
  iframe?: any;
}

const Login: React.FC<LoginProps> = ({ setUser, setCredits, iframe }) => {
  // #region CONSTANTS & STATE VARIABLES

  const baseURL2 = process.env.REACT_APP_BASEURL2;

  const navigate = useNavigate();

  const [onLogin, setOnLogin] = useState(false);

  const [emailText, setEmailText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [nameText, setNameText] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0);
  const [muted, setMuted] = useState(true);
  const [startedWatching, setStartedWatching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [watchedIntro, setWatchedIntro] = useState<any>(
    localStorage.getItem("watchedIntro")
  );
  const context = useContext<any>(ParentContext);
  const isBiz = context.isBiz4Biz || context.isHarbour ? false : true;
  const isHarbour = context.isHarbour ? true : false
  const isBiz4Biz = context.isBiz4Biz ? true : false
  // const isHarbourClub = true;

  // #endregion className='testBtn'

  // #region SHOW COMPONENTS

  // #endregion

  // #region WEB REQUESTS
  // const addUserToDB = (uid: any) => {
  //   axios
  //     .post(baseURL + "createUserAccount", {
  //       uid: uid,
  //       name: nameText,
  //       email: emailText,
  //       isHarbourClub: isHarbour,
  //       isBiz4Biz: isBiz4Biz,
  //       // referral: window.Rewardful.referral,
  //     })
  //     .then((response) => {
  //       let resVar = response.data;
  //       let stripeID = resVar.stripeID;

  //       let user = JSON.parse(localStorage.getItem("user")!);
  //       user.stripeCustomerID = stripeID;
  //       localStorage.setItem("user", JSON.stringify(user));
  //       setUser(user);
  //       localStorage.setItem("isHarbourClub", (!isBiz).toString());
  //     });
  // };

  const getUserFromDB = async (uid: any) => {
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      try {
        const response = await axiosWithToken.get(`${baseURL2}api/auth/account-dashboard-details`);
        return response.data;
      } catch (error) {
        console.error('Error fetching user from DB:', error);
      }
    }
  };

  // #endregion

  // #region BUTTONS CLICKED
  const changeScreen = () => {
    setOnLogin(!onLogin);
    setNameText("");
    setEmailText("");
    setPasswordText("");
    setNameError("");
    setEmailError("");
    setPasswordError("");
  };

  // #endregion

  // #region OTHER

  // #endregion
  const login = () => {
    signInWithEmailAndPassword(auth, emailText, passwordText)
      .then(async (userCredential) => {
        // Signed in

        const user = userCredential.user;
        // const idToken = await getIdToken(user);
        // localStorage.setItem("idToken", idToken);

        const data: any = await getUserFromDB(user.uid);

        localStorage.setItem("user", JSON.stringify(data));
        Mixpanel.identify(data.uid);
        Mixpanel.people.set({
          $name: data.name,
          $email: data.email,
          plan: data.plan,
        });
        Mixpanel.track("User Login", {
          $name: data?.name,
          $email: data?.email,
          "Plan": data?.plan,
          "User Type": "Buyer",
          "User ID": data.uid,
          "Date": new Date().toISOString(),
        })
        // navigate(`../search`, { replace: true});
        const userData = auth.currentUser;
        if (!iframe && userData !== null && userData.emailVerified) {
          // navigate(`../`, { replace: true});
        } else if (!iframe) {
          navigate(`../verify-email`, { replace: true });
        }
      })
      .catch((error) => {
        // An error happened.
        const errorCode = error.code;
        if (errorCode === "auth/invalid-email") {
          setEmailError("Invalid email or username structure");
        }
        if (errorCode === "auth/user-not-found") {
          setEmailError("No account exists with this email or username");
        }
        if (errorCode === "auth/wrong-password") {
          setPasswordError("This is the incorrect password for this account");
        }
        if (errorCode === "auth/internal-error") {
          setPasswordError("An internal error occured");
        }
        if (errorCode === "auth/invalid-credential") {
          setPasswordError("Invalid username or password");
        }
        // setErrorText("Error: "+ errorMessage)
      });
  };

  const register = () => {
    localStorage.setItem("currentlyRegistering", "true");
    setIsLoading(true);
    axios
      .post(`${baseURL2}api/auth/register`, {
        name: nameText,
        email: emailText,
        password: passwordText,
        isHarbourClub: isHarbour,
        isBiz4Biz: isBiz4Biz,
      })
      .then(async (response) => {
        const userUid = response.data;

        let userJSON = {
          uid: userUid,
          name: nameText,
          email: emailText,
          credits: 50,
          companiesExported: [],
          exports: [],
          plan: "free",
          savedFilters: [],
          stripeCustomerID: "",
          usedCredits: 0,
          isHarbourClub: !isBiz,
        };

        Mixpanel.identify(userUid);
        Mixpanel.people.set({
          $name: nameText,
          $email: emailText,
          plan: "free",
        });
        Mixpanel.track("Sign Up", {
          "User ID": userUid,
          "Date": new Date().toISOString(),
          $name: nameText,
          $email: emailText,
          "User Type": "Buyer",
        });

        setUser(userJSON);
        if (!iframe) {
          setCredits(25);
        }

        localStorage.setItem("newUser", "true");

        try {
          const userCredential = await signInWithEmailAndPassword(auth, emailText, passwordText);
          const userData = userCredential.user;
          localStorage.setItem("user", JSON.stringify(userJSON));

          // const idToken = await getIdToken(userData);
          // if (idToken) {
          //   localStorage.setItem("idToken", idToken);
          // }
          if (!iframe && userData !== null && userData.emailVerified) {
            navigate(`../`, { replace: true });
          } else {
            // sendEmailVerification(user).then(function() {
            //     // Verification code sent successfully.
            //   }).catch(function(error) {
            //     // Handle errors here.
            //   });

            if (!iframe) {
              navigate(`../verify-email`, { replace: true });
            }
            setIsLoading(false);
          }

        } catch (firebaseError) {
          console.error("Firebase Sign In Error:", firebaseError);
        }

      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.response?.data?.statusCode || error.code;

        if (errorCode === 409) {
          setEmailError("This email is already being used by an existing user");
        }
        else {
          setPasswordError("An internal error occurred. Please try again later");
        }
      });
  };

  const submitClicked = () => {
    setEmailError("");
    setPasswordError("");
    setNameError("");

    if (!onLogin && nameText === "") {
      setNameError("Please enter your name");
    } else if (emailText === "") {
      setEmailError("Please enter an email");
    } else if (passwordText === "") {
      setPasswordError("Please enter a password");
    } else if (!validateEmail(emailText)) {
      setEmailError("Invalid email structure");
    } else if (passwordText.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else {
      if (onLogin) {
        login();
      } else {
        register();
      }
    }
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const policyLinkClicked = (end: string): void => {
    const url = `${bizCrunchPolicyUrl}${end}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const forgotPasswrod = () => {
    navigate(`../passwordReset`, { replace: true });
  };

  const handleIntroWatched = () => {
    setTimeout(() => {
      setWatchedIntro(true);
    }, 3000);
  };

  const handleDismissClick = () => {
    setWatchedIntro(true);
    setPlaying(false);
  };

  const handlePauseClick = () => {
    setPlaying(false);
    setVolume(0);
    setMuted(true);
  };

  const handlePlayClick = () => {
    setPlaying(true);
    setVolume(1);
    setMuted(false);
    setStartedWatching(true);
  };

  useEffect(() => {
    if (watchedIntro) {
      localStorage.setItem("watchedIntro", "true");
    }
  }, [watchedIntro]);

  return (
    <div className={`loginScreen ${iframe ? "iframe" : ""}`}>

      {isLoading && <Loader />}

      <div
        className={`cursor-pointer z-50 absolute justify-center items-center ${watchedIntro || isBiz ? "hidden" : ""
          }`}
      >
        <ReactPlayer
          className="overflow-hidden rounded-xl border-8"
          playing={playing}
          url={isBiz4Biz ? vimeoBiz4BizUrl : isHarbour ? youtubeUrl : vimeoUrl}
          width="640px"
          height="368px"
          title={"BizCrunch intro video"}
          autoplay
          controls={true}
          muted={muted}
          volume={volume}
          onEnded={handleIntroWatched}
          onPlay={handlePlayClick}
          onPause={handlePauseClick}
        />
        {startedWatching && (
          <div
            className="absolute top-0 left-0 w-10 h-10 p-2 bg-gray-200 rounded-full"
            onClick={handleDismissClick}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>

      <div
        className={`${watchedIntro || isBiz ? "" : "blur-lg pointer-events-none"
          }`}
      >
        <div className="loginTitles">
          <div className="row ai-centre gap12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8041 12H29.4298C33.4926 12 36.7861 15.4252 36.7861 19.6505C36.7861 21.6095 36.078 23.3966 34.9136 24.75C36.078 26.1034 36.7861 27.8905 36.7861 29.8495C36.7861 34.0748 33.4926 37.5 29.4298 37.5L13.9003 37.4997V27.2991H18.8041V22.2006H21.2562V22.1991H29.4298C29.4544 22.1991 29.479 22.1992 29.5035 22.1995C30.8237 22.1589 31.8819 21.0332 31.8819 19.6505C31.8819 18.242 30.7841 17.1003 29.4298 17.1003H18.8041V12ZM18.8044 32.3997V27.3009H29.4298C29.4544 27.3009 29.479 27.3008 29.5035 27.3005C30.8237 27.3411 31.8819 28.4668 31.8819 29.8495C31.8819 31.258 30.7841 32.3997 29.4298 32.3997H18.8044Z"
                fill="black"
              />
              <path
                d="M13.8998 17.1034H9V12.0031H13.9042V17.0981H18.804V22.1984H13.8998V17.1034Z"
                fill="black"
              />
            </svg>

            {!isBiz && <span className="display-xs bold gray-900">X</span>}

            {!isBiz && (
              <img
                className="width48px"
                src={isHarbour ? "/assets/HC_Logo.png" : "/assets/logoB4B.jpg"}
                alt="harborclubLogo"
              />
            )}
          </div>

          <span className="display-xs semibold gray-900">
            {onLogin ? "Login to Bizcrunch" : "Supercharge your search"}{" "}
          </span>
          <span className="text-md regular gray-600">
            {onLogin ? "No account? " : "Already registered? "}
            <span className="underline" onClick={changeScreen}>
              {onLogin ? "Register" : "Sign in"}
            </span>
          </span>
        </div>

        <div className="loginInputs ">
          {!onLogin && (
            <div className="loginInput">
              <span className="text-sm medium gray-700">Full name</span>
              <input
                className={`textInput ${nameError !== "" ? "error" : ""}`}
                type="text"
                placeholder="John Doe"
                value={nameText}
                onChange={(e) => setNameText(e.target.value)}
              />
              {nameError !== "" && (
                <span className="text-sm regular error-500">{nameError}</span>
              )}
            </div>
          )}

          <div className="loginInput">
            <span className="text-sm medium gray-700">Email address</span>
            <input
              className={`textInput ${emailError !== "" ? "error" : ""}`}
              type="email"
              autoCorrect="off"
              autoCapitalize="none"
              placeholder="e.g. john@example.com"
              value={emailText}
              onChange={(e) => setEmailText(e.target.value)}
            />
            {emailError !== "" && (
              <span className="text-sm regular error-500">{emailError}</span>
            )}
          </div>

          <div className="loginInput">
            <span className="text-sm medium gray-700">Password</span>
            <input
              className={`textInput ${passwordError !== "" ? "error" : ""}`}
              type="password"
              placeholder="xxxxxxxx"
              value={passwordText}
              onChange={(e) => setPasswordText(e.target.value)}
            />
            {passwordError !== "" && (
              <span className="text-sm regular error-500">{passwordError}</span>
            )}
            {!iframe && (
              <div className="loginForgotRow">
                <span
                  className="underline text-sm regular gray-600"
                  onClick={forgotPasswrod}
                >
                  Forgot your password?
                </span>
              </div>
            )}
          </div>

          <button className="loginBtn primaryButton" onClick={submitClicked}>
            {onLogin ? "Sign in" : "Register"}
          </button>
        </div>

        {!onLogin && (
          <div className="loginTerms text-xs regular gray-600">
            <span>By registering you accept our</span>
            <div className="loginTermsRow">
              <span
                className="underline"
                onClick={() => policyLinkClicked("terms")}
              >
                {"Terms of Use"}
              </span>
              ,
              <span
                className="underline"
                onClick={() => policyLinkClicked("data")}
              >
                {"Data Policy"}
              </span>{" "}
              and{" "}
              <span
                className="underline"
                onClick={() => policyLinkClicked("privacy")}
              >
                {" Privacy Policy."}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;