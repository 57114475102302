import React from 'react';
import '../../styling/filters.css';

interface TooltipProps {
    icon: 'normal' | 'dark' | 'pink' | 'question';
    text: string;
    side?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ icon, text, side }) => {

    const getIcon = (): string => {
        if (icon === "normal") return 'assets/info.png';
        else if (icon === "dark") return 'assets/info-dark.png';
        else if (icon === "pink") return 'assets/info-pink.png';
        else if (icon === "question") return 'assets/question-circle.png';
        return '';
    };

    return (
        <div className="tooltipInner">
            <img className='safInfo' src={getIcon()} alt='infoIcon' />
            <span className={`tooltipInnertext ${side ? "side" : ""} text-sm regular`}>
                {text}
            </span>
        </div>
    );
};

export default Tooltip;
