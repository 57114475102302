import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';

interface PricingAuthCardInputProps {
    backClicked: () => void;
}

const PricingAuthCardInput: React.FC<PricingAuthCardInputProps> = ({ backClicked }) => {
    const [step, setStep] = useState<number>(1);

    useEffect(() => {
        setStep(step);
    }, [step]);

    return (
        <div className='column ai-start'>
            <div className='pricingTile padding50'>
                <button className='secondaryButton' onClick={backClicked}>Go back</button>
            </div>
        </div>
    );
}

export default PricingAuthCardInput;
