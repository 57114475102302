import '../styling/home.css';
import React, { useEffect, useState } from 'react';
import axiosWithToken from 'axiosWithToken';

export default function Account() {
  // #region CONSTANTS & STATE VARIABLES
  const baseURL2 = process.env.REACT_APP_BASEURL2!;
  const [emailText, setEmailText] = useState<string>('');
  const [nameText, setNameText] = useState<string>('');
  // #endregion

  // #region WEB REQUESTS
  const getAccountDetails = () => {
    axiosWithToken
      .get<any>(baseURL2 + 'api/auth/account-dashboard-details')
      .then((response) => {
        const data = response.data;
        setEmailText(data.email);
        setNameText(data.name);
      });
  };
  // #endregion

  // #region EFFECTS
  useEffect(() => {
    getAccountDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // #endregion

  return (
    <div className="homeScreen">
      <h2>Account</h2>
      <span><strong>Name: </strong>{nameText}</span>
      <span><strong>Email: </strong>{emailText}</span>
    </div>
  );
}
